import classNames from 'classnames/bind';
import React from 'react';
import { MdOutlineSatelliteAlt, MdOutlineBolt } from 'react-icons/md';

import styles from './Status.module.scss';

const cx = classNames.bind(styles);

const Status = ({ data: status }) => {
  return (
    <div className={cx('container')}>
      {Object.entries(status).map(([key, value], index) => {
        // 배터리 전류
        if (key === 'current') {
          const current = value ? `${value.toFixed(1)}A` : '-';

          return (
            <div key={index} className={cx('item')}>
              <MdOutlineBolt size={16} />
              <div className={cx('value')}>{current}</div>
            </div>
          );
        }
        // 배터리 전압
        else if (key === 'voltage') {
          const voltage = value ? `${value.toFixed(1)}V` : '-';

          return (
            <div key={index} className={cx('item')}>
              <MdOutlineBolt size={16} />
              <div className={cx('value')}>{voltage}</div>
            </div>
          );
        }
        // 위성 수
        else if (key === 'satellites') {
          const blink = value !== null && value < 10;

          return (
            <div key={index} className={cx(['item', { blink }])} style={{ gap: 4 }}>
              <MdOutlineSatelliteAlt size={15} />
              <div className={cx('value')}>{value ?? 0}</div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default Status;
