import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useDispatch } from 'react-redux';

import styles from './ColorPicker.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import { patchRobot } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = ({ robot }) => {
  const dispatch = useDispatch();
  const [color, setColor] = useState(robot.color);

  const close = () => {
    modal.hide();
  };

  const changeColor = (color) => {
    setColor(color.hex);
  };

  const doSave = () => {
    patchRobot(robot.id, { color }).then(({ success }) => {
      if (success) {
        dispatch(actions.robot.changeColor(robot.id, color));
        close();
      }
    });
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <ChromePicker width="264px" color={color} disableAlpha={true} onChange={changeColor} />
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doSave}>
          Save
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
