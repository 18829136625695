import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo, useRef } from 'react';
import {
  RiCompasses2Line,
  RiDvdLine,
  RiGitPullRequestLine,
  RiTimerLine,
  RiRestartLine,
  RiSpeedUpLine,
  RiMore2Line,
  RiArrowUpDownLine,
  RiPinDistanceLine,
  RiVipDiamondLine,
  RiRemoteControlLine,
  RiToolsLine,
  RiListSettingsLine,
  RiToggleLine,
} from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import CondDistance from './CondDistance';
import CondYaw from './CondYaw';
import CusSurvey from './CusSurvey';
import DoAutotuneEnable from './DoAutotuneEnable';
import DoChangeSpeed from './DoChangeSpeed';
import DoGripper from './DoGripper';
import DoGuidedLimits from './DoGuidedLimits';
import DoJump from './DoJump';
import DoParachute from './DoParachute';
import DoRepeatRelay from './DoRepeatRelay';
import DoRepeatServo from './DoRepeatServo';
import DoSetRelay from './DoSetRelay';
import DoSetServo from './DoSetServo';
import styles from './index.module.scss';
import NavContinueAndChangeAlt from './NavContinueAndChangeAlt';
import NavDelay from './NavDelay';
import NavGuidedEnable from './NavGuidedEnable';
import NavLand from './NavLand';
import NavLoiterTime from './NavLoiterTime';
import NavLoiterToAlt from './NavLoiterToAlt';
import NavLoiterTurns from './NavLoiterTurns';
import NavReturnToLaunch from './NavReturnToLaunch';
import NavTakeoff from './NavTakeoff';
import NavWaypoint from './NavWaypoint';

import actions from '@/actions';
import AccordionCaret from '@/components/ui/AccordionCaret';
import Marker from '@/components/ui/map/Marker';
import EventEmitter from '@/libs/EventEmitter';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const markers = useSelector((state) => state.editor.markers);
  const [isOpenBody, setIsOpenBody] = useState(true);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const moreRef = useRef();
  const panelRef = useRef();

  // Land, Loiter 3종 (Time, Altitude, Turns), Waypoint 경우 유형 변경 가능
  const isChangeableType = useMemo(() => {
    return ['navLand', 'navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navWaypoint'].includes(missionItem.type);
  }, [missionItem.type]);

  const markerLabel = useMemo(() => {
    const { id, type } = missionItem;

    if (markers[id]) {
      return markers[id].label;
    } else if (type === 'cusSurvey') {
      return markers[`${id}/start`].label;
    }

    return null;
  }, [markers]);

  const Icon = useMemo(() => {
    switch (missionItem.type) {
      case 'condDistance':
        return RiPinDistanceLine;
      case 'condYaw':
        return RiRestartLine;
      case 'doAutotuneEnable':
        return RiToolsLine;
      case 'doChangeSpeed':
        return RiSpeedUpLine;
      case 'doGripper':
        return RiCompasses2Line;
      case 'doGuidedLimits':
        return RiListSettingsLine;
      case 'doJump':
        return RiGitPullRequestLine;
      case 'doParachute':
        return RiVipDiamondLine;
      case 'doRepeatRelay':
      case 'doSetRelay':
        return RiToggleLine;
      case 'doRepeatServo':
      case 'doSetServo':
        return RiDvdLine;
      case 'navContinueAndChangeAlt':
        return RiArrowUpDownLine;
      case 'navDelay':
        return RiTimerLine;
      case 'navGuidedEnable':
        return RiRemoteControlLine;

      // Use default marker icon below for these types.
      case 'navLand':
      case 'navLoiterTime':
      case 'navLoiterToAlt':
      case 'navLoiterTurns':
      case 'navReturnToLaunch':
      case 'navTakeoff':
      case 'navWaypoint':
      case 'cusSurvey':
      default:
        return;
    }
  }, [missionItem.type]);

  const specs = useMemo(() => {
    return store.getState().editor.specs;
  }, []);

  useMountEffect(() => {
    const toggle = (nextIsOpenBody) => {
      if (nextIsOpenBody === undefined) {
        setIsOpenBody((prev) => !prev);
      } else {
        setIsOpenBody(nextIsOpenBody);
      }
    };

    const subscribeToken = EventEmitter.subscribe(`missionItem/${missionItem.id}/toggle`, toggle);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  useUpdateEffect(() => {
    if (isOpenPanel) {
      document.addEventListener('mousedown', unfocusPanel);
    } else {
      document.removeEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [isOpenPanel]);

  const changeType = (e) => {
    dispatch(actions.editor.changeMissionItemType(index, e.target.value));
  };

  const toggleBody = () => {
    setIsOpenBody(!isOpenBody);
  };

  const togglePanel = (e) => {
    e.stopPropagation();
    setIsOpenPanel(!isOpenPanel);

    const rect = e.currentTarget.getBoundingClientRect();
    panelRef.current.style.top = `${rect.y + 28}px`;
    panelRef.current.style.left = `${rect.x}px`;
  };

  const unfocusPanel = (e) => {
    if (moreRef.current.contains(e.target)) return;
    if (panelRef.current.contains(e.target)) return;

    setIsOpenPanel(false);
  };

  const remove = () => {
    dispatch(actions.editor.removeMissionItem(index));
  };

  const moveUp = () => {
    setIsOpenPanel(false);

    if (index < 2) {
      notifier.error('Cannot be moved up anymore.');
      return;
    }

    dispatch(actions.editor.shiftMissionItem(index, -1));
  };

  const moveDown = () => {
    setIsOpenPanel(false);

    if (index === store.getState().editor.missionItems.length - 1) {
      notifier.error('Cannot be moved down anymore.');
      return;
    }

    dispatch(actions.editor.shiftMissionItem(index, 1));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggleBody}>
        <div className={cx('left')}>
          {markerLabel && (
            <div className={cx('marker')}>
              <Marker data={{ label: markerLabel }} />
            </div>
          )}
          {!markerLabel && (
            <div className={cx('icon')}>
              <Icon size={20} color="white" />
            </div>
          )}
          {
            // prettier-ignore
            <select
              value={missionItem.type}
              disabled={!isChangeableType}
              className={cx('type')}
              onClick={(e) => e.stopPropagation()}
              onChange={changeType}>
                <option value="doAutotuneEnable" hidden>Autotune Enable</option>
                <option value="navContinueAndChangeAlt" hidden>Change Altitude</option>
                <option value="doChangeSpeed" hidden>Change Speed</option>
                <option value="doRepeatRelay" hidden>Cycle Relay</option>
                <option value="doRepeatServo" hidden>Cycle Servo</option>
                <option value="navDelay" hidden>Delay Until</option>
                <option value="doGripper" hidden>Gripper</option>
                <option value="navGuidedEnable" hidden>Guided Enable</option>
                <option value="doGuidedLimits" hidden>Guided Limits</option>
                <option value="doJump" hidden>Jump To Item</option>
                {specs.frame !== 'boat' && <option value="navLand">Land</option>}
                {specs.frame !== 'boat' && <option value="navLoiterToAlt">Loiter (Altitude)</option>}
                <option value="navLoiterTime">Loiter (Time)</option>
                {specs.firmware === 'ardupilot' && <option value="navLoiterTurns">Loiter (Turns)</option>}
                <option value="navReturnToLaunch" hidden>Return To Launch</option>
                <option value="doSetRelay" hidden>Set Relay</option>
                <option value="doSetServo" hidden>Set Servo</option>
                <option value="cusSurvey" hidden>Survey</option>
                <option value="navTakeoff" hidden>Takeoff</option>
                <option value="doParachute" hidden>Trigger Parachute</option>
                <option value="condDistance" hidden>Wait For Distance</option>
                <option value="condYaw" hidden>Wait For Yaw</option>
                <option value="navWaypoint">Waypoint</option>
            </select>
          }
        </div>
        <div className={cx('right')}>
          {missionItem.type !== 'navTakeoff' && (
            <div ref={moreRef} className={cx('more')} onClick={togglePanel}>
              <RiMore2Line size={14} color="white" />
            </div>
          )}
          <AccordionCaret up={isOpenBody} size={32} />
        </div>
      </div>
      <div className={cx(['body', { open: isOpenBody }])}>
        {
          // prettier-ignore
          <div className={cx('inner')}>
            {missionItem.type === 'condDistance' && <CondDistance index={index} data={missionItem} />}
            {missionItem.type === 'condYaw' && <CondYaw index={index} data={missionItem} />}
            {missionItem.type === 'doAutotuneEnable' && <DoAutotuneEnable index={index} data={missionItem} />}
            {missionItem.type === 'doChangeSpeed' && <DoChangeSpeed index={index} data={missionItem} />}
            {missionItem.type === 'doGripper' && <DoGripper index={index} data={missionItem} />}
            {missionItem.type === 'doGuidedLimits' && <DoGuidedLimits index={index} data={missionItem} />}
            {missionItem.type === 'doJump' && <DoJump index={index} data={missionItem} />}
            {missionItem.type === 'doParachute' && <DoParachute index={index} data={missionItem} />}
            {missionItem.type === 'doRepeatRelay' && <DoRepeatRelay index={index} data={missionItem} />}
            {missionItem.type === 'doRepeatServo' && <DoRepeatServo index={index} data={missionItem} />}
            {missionItem.type === 'doSetRelay' && <DoSetRelay index={index} data={missionItem} />}
            {missionItem.type === 'doSetServo' && <DoSetServo index={index} data={missionItem} />}
            {missionItem.type === 'navContinueAndChangeAlt' && (<NavContinueAndChangeAlt index={index} data={missionItem} />)}
            {missionItem.type === 'navDelay' && <NavDelay index={index} data={missionItem} />}
            {missionItem.type === 'navGuidedEnable' && <NavGuidedEnable index={index} data={missionItem} />}
            {missionItem.type === 'navLand' && <NavLand index={index} data={missionItem} />}
            {missionItem.type === 'navLoiterTime' && <NavLoiterTime index={index} data={missionItem} />}
            {missionItem.type === 'navLoiterToAlt' && <NavLoiterToAlt index={index} data={missionItem} />}
            {missionItem.type === 'navLoiterTurns' && <NavLoiterTurns index={index} data={missionItem} />}
            {missionItem.type === 'navReturnToLaunch' && <NavReturnToLaunch />}
            {missionItem.type === 'navTakeoff' && <NavTakeoff index={index} data={missionItem} />}
            {missionItem.type === 'navWaypoint' && <NavWaypoint index={index} data={missionItem} />}
            {missionItem.type === 'cusSurvey' && <CusSurvey index={index} data={missionItem} />}
          </div>
        }
      </div>
      <div ref={panelRef} className={cx(['panel', { open: isOpenPanel }])}>
        <div className={cx('arrow')} />
        <div className={cx('item')} onClick={remove}>
          Remove
        </div>
        <div className={cx('item')} onClick={moveUp}>
          Move Up
        </div>
        <div className={cx('item')} onClick={moveDown}>
          Move Down
        </div>
      </div>
    </div>
  );
};

export default MissionItem;
