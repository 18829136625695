import useMountEffect from '@restart/hooks/useMountEffect';
import { radiansToDegrees } from '@turf/turf';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Attitude.module.scss';

import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Attitude = ({ data: robotId, className }) => {
  const [attitude, setAttitude] = useState({ roll: 0, pitch: 0, yaw: 0 });

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/attitude`, ({ roll, pitch, yaw }) => {
      roll = radiansToDegrees(roll);
      pitch = radiansToDegrees(pitch);
      yaw = radiansToDegrees(yaw);
      if (yaw < 0) yaw += 360;

      setAttitude({ roll, pitch, yaw });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx(['container', className])}>
      <div className={cx('gauges')}>
        <div className={cx(['gauge', 'left'])}>
          <img
            src={require('@/assets/images/attitude/right-background.png')}
            alt=""
            className={cx('background')}
            style={{ transform: `rotate(${-attitude.roll}deg) translateY(${attitude.pitch * 1.6}px)` }} // 이미지 위치 보정 상수 1.6
          />
          <img src={require('@/assets/images/attitude/shadow.png')} alt="" className={cx('shadow')} />
          <div className={cx('roll')} />
          <img
            src={require('@/assets/images/attitude/right-coord.png')}
            alt=""
            draggable="false"
            className={cx('coord')}
            style={{ transform: `rotate(${-attitude.roll}deg)` }}
          />
          <img src={require('@/assets/images/attitude/right-arrow.png')} alt="" draggable="false" />
        </div>
        <div className={cx(['gauge', 'right'])}>
          <img src={require('@/assets/images/attitude/shadow.png')} alt="" className={cx('shadow')} />
          <div className={cx('tics')}>
            {new Array(24).fill().map((item, index) => {
              const option = {};
              if ((index * 15) % 90 === 0) {
                option.big = true;
              } else if ((index * 15) % 30 === 0) {
                option.medium = true;
              } else {
                option.small = true;
              }

              return (
                <div key={index} className={cx(['tic', option])} style={{ transform: `rotate(${index * 15}deg)` }} />
              );
            })}
            <div className={cx(['label', 'n'])}>N</div>
            <div className={cx(['label', 'e'])}>E</div>
            <div className={cx(['label', 's'])}>S</div>
            <div className={cx(['label', 'w'])}>W</div>
          </div>
          <div className={cx('arrowWrapper')} style={{ transform: `rotate(${attitude.yaw}deg)` }}>
            <img
              src={require('@/assets/images/attitude/left-arrow.png')}
              alt=""
              draggable="false"
              className={cx('arrow')}
            />
          </div>
          <div className={cx('value')}>{String(Math.floor(attitude.yaw)).padStart(3, 0)}</div>
        </div>
      </div>
    </div>
  );
};

export default Attitude;
