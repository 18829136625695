import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    altitude: useRef(),
    mode: useRef(),
  }).current;

  useEffect(() => {
    domRefs.altitude.current.value = missionItem.data.altitude.toFixed(1);
    domRefs.mode.current.value = missionItem.data.mode;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Relative Altitude" unit="m">
        <input
          ref={domRefs.altitude}
          name="altitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Mode">
        <select ref={domRefs.mode} name="mode" onChange={handleBlur}>
          <option value="0">Neutral</option>
          <option value="1">Climb</option>
          <option value="2">Descend</option>
        </select>
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
