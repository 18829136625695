import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      {missionItem.data.name && (
        <label>
          Name
          <div className={cx('fieldWrapper')}>
            <input value={missionItem.data.name} readOnly />
            <span className={cx('unit')} />
          </div>
        </label>
      )}
      <label>
        Latitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.position.lat.toFixed(7)} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Longitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.position.lng.toFixed(7)} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Relative Altitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.altitude.toFixed(1)} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
      {missionItem.data.speed && (
        <label>
          Speed
          <div className={cx('fieldWrapper')}>
            <input value={missionItem.data.speed} readOnly />
            <span className={cx('unit')}>m/s</span>
          </div>
        </label>
      )}
    </div>
  );
};

export default Item;
