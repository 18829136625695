import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';

import styles from './index.module.scss';

import EmptyList from '@/components/ui/EmptyList';
import API from '@/helpers/API';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Checklist = ({ robot }) => {
  const questionRef = useRef();
  const [checklist, setChecklist] = useState();

  useMountEffect(() => {
    API.get(`/robots/${robot.id}/checklist`).then(({ success, data }) => {
      if (success) {
        setChecklist(data);
      }
    });
  });

  const doAdd = () => {
    const question = questionRef.current.value.trim();
    if (question === '') {
      notifier.error('Please enter a question.');
      return;
    }

    API.post(`/robots/${robot.id}/checklist`, { question }).then(({ success, data }) => {
      if (success) {
        // 새 항목 추가 시 체크리스트 확인 기록 삭제
        localStorage.removeItem(`@preventChecklist/${robot.id}`);

        setChecklist([...checklist, data]);
        questionRef.current.value = '';
      }
    });
  };

  const doDelete = (e) => {
    const { id } = e.currentTarget.dataset;

    API.delete(`/robots/${robot.id}/checklist/${id}`).then(({ success }) => {
      if (success) {
        setChecklist(checklist.filter((item) => item.id !== id));
      }
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx(['column', 'no'])}>No</div>
        <div className={cx(['column', 'question'])}>Question</div>
        <div className={cx(['column', 'delete'])}>Delete</div>
      </div>
      <div className={cx('body')}>
        {checklist?.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList />
          </div>
        )}
        {checklist?.map((item, index) => (
          <div key={index} className={cx('row')}>
            <div className={cx(['column', 'no'])}>{index + 1}</div>
            <div className={cx(['column', 'question'])}>{item.question}</div>
            <div className={cx(['column', 'delete'])}>
              <button type="button" className={cx('button')} data-id={item.id} onClick={doDelete}>
                <RiDeleteBinLine size={14} color="white" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className={cx('form')}>
        <input ref={questionRef} type="text" placeholder="Please enter the new question." />
        <button type="button" className={cx('button')} onClick={doAdd}>
          Add
        </button>
      </div>
    </div>
  );
};

export default Checklist;
