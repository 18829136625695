import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useRef } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const timeRef = useRef();

  useMountEffect(() => {
    const updateUTCTime = () => {
      timeRef.current.innerText = moment().utc().format('HH:mm:ss');
    };

    updateUTCTime();
    const intervalId = setInterval(updateUTCTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className={cx('container')}>
      <label>
        Hold
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.hold} readOnly />
          <span className={cx('unit')}>secs</span>
        </div>
      </label>
      <label>
        Hour (UTC)
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.hour} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Minute (UTC)
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.minute} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Second (UTC)
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.second} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <div className={cx('message')}>
        The current time in UTC is <span ref={timeRef} />
      </div>
    </div>
  );
};

export default Item;
