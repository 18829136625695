import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [isAgreed, setIsAgreed] = useState(false);

  const doAgree = () => {
    setIsAgreed(true);
  };

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/disarm/arduplane', [[], []]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('disarm')}>Force Disarming</div>
      {isAgreed && <ConfirmSlider onConfirm={doCommand} />}
      {!isAgreed && (
        <div className={cx('option')}>
          <Checkbox checked={isAgreed} size={16} onClick={doAgree} />
          I understand that this command
          <br />
          may cause the drone to crash.
        </div>
      )}
    </div>
  );
};

export default Action;
