import useMountEffect from '@restart/hooks/useMountEffect';
import moment from 'moment';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import ChecklistModal from '@/components/modals/Checklist';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  useMountEffect(() => {
    const isNeedChecklist = robots.some((robot) => {
      const storeKey = `@preventChecklist/${robot.id}`;
      return localStorage.getItem(storeKey) !== moment().format('YYYYMMDD');
    });

    if (!isNeedChecklist) return;

    dispatch(actions.command.toggle());
    modal.show(ChecklistModal, { robots });
  });

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/arm/arduplane', [[], []]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);

      OlMap.removeShoot(robot.id);
      OlMap.removeFootprint(robot.id);
    });

    dispatch(actions.command.toggle());
  };

  return <ConfirmSlider onConfirm={doCommand} />;
};

export default Action;
