import { RiToolsLine } from 'react-icons/ri';

// Inspection
import Diagnostics from './01_Inspection/01_Diagnostics';

const groups = [
  {
    name: 'Inspection',
    commands: [{ column: 0, name: 'Diagnostics', icon: RiToolsLine, component: Diagnostics }],
  },
];

export default groups;
