import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import Feature from 'ol/Feature';
import { MultiLineString, MultiPoint } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Circle, Fill } from 'ol/style';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import OlMap from '@/helpers/OlMap';
import CoordUtils from '@/utils/CoordUtils';

const GuidePaths = () => {
  const guideMissions = useSelector((state) => state.editor.guideMissions);
  const layer = useRef();
  const map = OlMap.getMap();

  const paths = useMemo(() => {
    return guideMissions.map(({ path }) => path);
  }, [guideMissions]);

  useUpdateEffect(() => {
    const positionGroups = paths.map((path) =>
      path.map(({ position }) => fromLonLat(CoordUtils.arrayFromObject(position)))
    );

    // 기존 경로 존재하는 경우
    if (layer.current) {
      const features = layer.current.getSource().getFeatures();

      features.forEach((feature) => {
        // 경로인 경우
        if (feature.getGeometry().getType() === 'MultiLineString') {
          feature.getGeometry().setCoordinates(positionGroups);
        }
        // 경로점인 경우
        else {
          feature.getGeometry().setCoordinates(positionGroups.flat());
        }
      });
    }
    // 기존 경로 존재하지 않는 경우
    else {
      const paths = new Feature(new MultiLineString(positionGroups));
      const points = new Feature(new MultiPoint(positionGroups.flat()));

      const source = new VectorSource({ features: [paths, points], wrapX: false });
      layer.current = new VectorLayer({
        source,
        declutter: true,
        style: new Style({
          // 경로
          stroke: new Stroke({
            color: [255, 255, 255, 0.5],
            width: 4,
          }),
          // 경로점
          image: new Circle({
            radius: 4,
            fill: new Fill({ color: [0, 0, 0, 0.3] }),
            stroke: new Stroke({
              color: [255, 255, 255, 0.5],
              width: 2,
            }),
          }),
        }),
      });
      map.addLayer(layer.current);
    }
  }, [paths]);

  return null;
};

export default GuidePaths;
