import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      <label>
        Timeout
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.timeout} readOnly />
          <span className={cx('unit')}>secs</span>
        </div>
      </label>
      <label>
        Min Relative Altitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.minAlt} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
      <label>
        Max Relative Altitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.maxAlt} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
      <label>
        Horizontal Move Limit
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.horizMoveLimit} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
    </div>
  );
};

export default Item;
