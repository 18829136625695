import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

import BottomBar from './BottomBar';
import Chart from './Chart';
import GuideMissionList from './GuideMissionList';
import styles from './index.module.scss';
import Map from './Map';
import Panel from './Panel';
import TopBar from './TopBar';

import actions from '@/actions';
import SelectSpecsFirstModal from '@/components/modals/SelectSpecsFirst';
import WrongMissionModal from '@/components/modals/WrongMission';
import API from '@/helpers/API';
import { getMission } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();

  useMountEffect(() => {
    // API: 계정 조회
    API.get('/accounts').then(({ success, data }) => {
      if (success) {
        dispatch(actions.user.load(data));
      }
    });
  });

  useEffect(() => {
    if (params.id === 'new') {
      // 새 미션 편집 시작 시
      if (store.getState().editor.missionItems.length === 0) {
        setTimeout(() => modal.show(SelectSpecsFirstModal), 100);
      }
      return;
    }

    getMission(params.id).then(({ success, data }) => {
      if (success && data) {
        dispatch(actions.editor.load(data));
      } else {
        modal.show(WrongMissionModal);
      }
    });
  }, [params.id]);

  return (
    <>
      <Helmet>
        <title>M1UCS | Mission Hub</title>
      </Helmet>
      <div className={cx('container')}>
        <TopBar />
        <div className={cx('content')}>
          <Map />
          <div className={cx('leftTop')}>
            <Panel />
          </div>
          <div className={cx('leftBottom')}>
            <Chart />
          </div>
          <div className={cx('rightBottom')}>
            <GuideMissionList />
          </div>
        </div>
        <BottomBar />
      </div>
    </>
  );
};

export default Page;
