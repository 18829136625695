import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const direction = useMemo(() => {
    switch (missionItem.data.direction) {
      case -1:
        return 'Counter-Clockwise';
      case 0:
        return 'Shortest';
      case 1:
        return 'Clockwise';
      default:
        break;
    }
  }, [missionItem.data.direction]);

  const offset = useMemo(() => {
    switch (missionItem.data.offset) {
      case 0:
        return 'Absolute';
      case 1:
        return 'Relative';
      default:
        break;
    }
  }, [missionItem.data.offset]);

  return (
    <div className={cx('container')}>
      <label>
        Direction
        <div className={cx('fieldWrapper')}>
          <input value={direction} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Offset
        <div className={cx('fieldWrapper')}>
          <input value={offset} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Heading
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.heading.toFixed(1)} readOnly />
          <span className={cx('unit')}>deg</span>
        </div>
      </label>
      <label>
        Rate
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.rate.toFixed(1)} readOnly />
          <span className={cx('unit')}>deg/s</span>
        </div>
      </label>
    </div>
  );
};

export default Item;
