import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Status } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robotId }) => {
  const [gpsRaw, setGpsRaw] = useState({ satellitesVisible: null });
  const [batteryStatus, setBatteryStatus] = useState({ voltage: null });

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/gpsRaw`, setGpsRaw));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/batteryStatus`, setBatteryStatus));

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  return (
    <Status
      data={{
        voltage: batteryStatus.voltage / 1000,
        satellites: gpsRaw.satellitesVisible,
      }}
    />
  );
};

export default Component;
