import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';

import styles from './EKFStatus.module.scss';

import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const EKFStatus = ({ data: robotId }) => {
  const [ekfStatusReport, setEkfStatusReport] = useState({
    velocity: 0,
    positionHoriz: 0,
    positionVert: 0,
    compass: 0,
    terrain: 0,
    flags: {
      predPosHorizAbs: null,
      predPosHorizRel: null,
      constPosMode: null,
      posVertAgl: null,
      posVertAbs: null,
      posHorizAbs: null,
      posHorizRel: null,
      velocityVert: null,
      velocityHoriz: null,
      attitude: null,
    },
  });

  const flagValues = useMemo(() => {
    const nextFalgValues = {};
    Object.entries(ekfStatusReport.flags).forEach(([key, value]) => {
      if (value === null) {
        nextFalgValues[key] = '-';
      } else {
        nextFalgValues[key] = value ? 'On' : 'Off';
      }
    });

    return nextFalgValues;
  }, [ekfStatusReport]);

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/ekfStatusReport`, (value) => {
      const [
        predPosHorizAbs,
        predPosHorizRel,
        constPosMode,
        posVertAgl,
        posVertAbs,
        posHorizAbs,
        posHorizRel,
        velocityVert,
        velocityHoriz,
        attitude,
      ] = Array.from(value.flags.toString(2), (flag) => flag === '1');

      setEkfStatusReport({
        velocity: value.velocityVariance,
        positionHoriz: value.posHorizVariance,
        positionVert: value.posVertVariance,
        compass: value.compassVariance,
        terrain: value.terrainAltVariance,
        flags: {
          attitude,
          velocityHoriz,
          velocityVert,
          posHorizRel,
          posHorizAbs,
          posVertAbs,
          posVertAgl,
          constPosMode,
          predPosHorizRel,
          predPosHorizAbs,
        },
      });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('gauges')}>
        <div className={cx('item')}>
          <div className={cx('label')}>Velocity</div>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ width: `${ekfStatusReport.velocity * 100}%` }} />
            <div className={cx(['caution', 'v50'])} />
            <div className={cx(['caution', 'v80'])} />
            <div className={cx('value')}>{ekfStatusReport.velocity.toFixed(3)}</div>
          </div>
        </div>
        <div className={cx('item')}>
          <div className={cx('label')}>Horizontal Position</div>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ width: `${ekfStatusReport.positionHoriz}%` }} />
            <div className={cx(['caution', 'v50'])} />
            <div className={cx(['caution', 'v80'])} />
            <div className={cx('value')}>{ekfStatusReport.positionHoriz.toFixed(3)}</div>
          </div>
        </div>
        <div className={cx('item')}>
          <div className={cx('label')}>Vertical Position</div>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ width: `${ekfStatusReport.positionVert}%` }} />
            <div className={cx(['caution', 'v50'])} />
            <div className={cx(['caution', 'v80'])} />
            <div className={cx('value')}>{ekfStatusReport.positionVert.toFixed(3)}</div>
          </div>
        </div>
        <div className={cx('item')}>
          <div className={cx('label')}>Compass</div>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ width: `${ekfStatusReport.compass}%` }} />
            <div className={cx(['caution', 'v50'])} />
            <div className={cx(['caution', 'v80'])} />
            <div className={cx('value')}>{ekfStatusReport.compass.toFixed(3)}</div>
          </div>
        </div>
        <div className={cx('item')}>
          <div className={cx('label')}>Terrain</div>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ width: `${ekfStatusReport.terrain}%` }} />
            <div className={cx(['caution', 'v50'])} />
            <div className={cx(['caution', 'v80'])} />
            <div className={cx('value')}>{ekfStatusReport.terrain.toFixed(3)}</div>
          </div>
        </div>
      </div>
      <div className={cx('table')}>
        <div className={cx('header')}>
          <div className={cx('title')}>Flags</div>
        </div>
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td>Attitude</td>
              <td>{flagValues.attitude}</td>
            </tr>
            <tr>
              <td>Horizontal Velocity</td>
              <td>{flagValues.velocityHoriz}</td>
            </tr>
            <tr>
              <td>Vertical Velocity</td>
              <td>{flagValues.velocityVert}</td>
            </tr>
            <tr>
              <td>Horizontal Position (Relative)</td>
              <td>{flagValues.posHorizRel}</td>
            </tr>
            <tr>
              <td>Horizontal Position (Absolute)</td>
              <td>{flagValues.posHorizAbs}</td>
            </tr>
            <tr>
              <td>Vertical Position (Absolute)</td>
              <td>{flagValues.posVertAbs}</td>
            </tr>
            <tr>
              <td>Vertical Position (Above Ground)</td>
              <td>{flagValues.posVertAgl}</td>
            </tr>
            <tr>
              <td>Constant Position Mode</td>
              <td>{flagValues.constPosMode}</td>
            </tr>
            <tr>
              <td>Predicted Horizontal Position (Relative)</td>
              <td>{flagValues.predPosHorizRel}</td>
            </tr>
            <tr>
              <td>Predicted Horizontal Position (Absolute)</td>
              <td>{flagValues.predPosHorizAbs}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EKFStatus;
