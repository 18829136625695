import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      <label>
        Servo Instance Number
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.servo} readOnly />
        </div>
      </label>
      <label>
        Pulse Width Modulation
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.pwm} readOnly />
        </div>
      </label>
    </div>
  );
};

export default Item;
