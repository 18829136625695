import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Controller from './Controller';
import styles from './index.module.scss';

import actions from '@/actions';
import MODELS from '@/models';

const cx = classNames.bind(styles);

const Command = () => {
  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robot.robots);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const enableCommandId = useSelector((state) => state.command.enableCommandId);

  const modelId = useMemo(() => {
    if (!targetRobotIds.length === 0) return;

    const modelIds = robots.filter((robot) => targetRobotIds.includes(robot.id)).map((robot) => robot.model.id);
    const uniqueModelIds = [...new Set(modelIds)];
    if (uniqueModelIds.length > 1) return;

    return uniqueModelIds[0];
  }, [targetRobotIds]);

  const groups = useMemo(() => {
    if (!MODELS[modelId]?.commandGroups) return;

    // 최대 행수 찾기
    const maxRowCount = Math.max(
      ...MODELS[modelId].commandGroups.map(({ commands }) => {
        const counts = commands.reduce((acc, { column }) => {
          acc[column] = (acc[column] || 0) + 1;
          return acc;
        }, {});
        return Math.max(...Object.values(counts));
      })
    );

    return MODELS[modelId].commandGroups.map((group) => {
      const columns = [];
      group.commands.forEach((command) => {
        if (columns.length - 1 < command.column) {
          columns.push([]);
        }
        columns[command.column].push(command);
      });

      // 최대 행수까지 빈칸 채우기 위한 처리
      columns.forEach((commands) => {
        while (commands.length < maxRowCount) {
          commands.push(null);
        }
      });

      return {
        name: group.name,
        columns,
      };
    });
  }, [modelId]);

  useUpdateEffect(() => {
    if (!enableCommandId) return;

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [enableCommandId]);

  const onKeyDown = (e) => {
    // Enter, Space 키 입력에 의한 클릭 효과 방지
    if (['Enter', 'Space'].includes(e.code)) {
      e.preventDefault();
    }
    // ESC 키 입력 시 닫기
    if (e.code === 'Escape') {
      dispatch(actions.command.toggle(enableCommandId));
    }
  };

  const toggle = (e) => {
    dispatch(actions.command.toggle(e.currentTarget.dataset.commandId));
  };

  if (!groups) return;

  return (
    <div className={cx('container')}>
      <Controller />
      <div className={cx('groups')}>
        {groups.map((group) => (
          <div key={group.name} className={cx('group')}>
            <div className={cx('name')}>{group.name}</div>
            <div className={cx('columns')}>
              {group.columns.map((commands, columnIndex) => (
                <div key={columnIndex} className={cx('column')}>
                  {commands.map((command, commandIndex) => {
                    if (!command) {
                      return <div key={commandIndex} className={cx('blank')} />;
                    }

                    const commandId = `${modelId}/${group.name}/${command.name}`;
                    const pressed = commandId === enableCommandId;
                    const Icon = command.icon;

                    return (
                      <button
                        key={commandIndex}
                        type="button"
                        data-command-id={commandId}
                        className={cx(['button', { pressed }])}
                        onClick={toggle}>
                        <Icon size={20} color="white" />
                        <div className={cx('label')}>{command.name}</div>
                      </button>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Command;
