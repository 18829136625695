import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { MdOutlinePermIdentity, MdOutlineKey, MdOutlineLogout } from 'react-icons/md';
import { useSelector } from 'react-redux';

import styles from './AccountCircle.module.scss';

import ChangePasswordModal from '@/components/modals/ChangePassword';
import LogoutModal from '@/components/modals/Logout';
import ModifyUserInfoModal from '@/components/modals/ModifyUserInfo';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const AccountCircle = () => {
  const user = useSelector((state) => state.user);
  const [showPanel, setShowPanel] = useState(false);
  const thumbRef = useRef();
  const panelRef = useRef();

  useUpdateEffect(() => {
    if (showPanel) {
      document.addEventListener('mousedown', unfocusPanel);
    } else {
      document.removeEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [showPanel]);

  const unfocusPanel = (e) => {
    if (thumbRef.current.contains(e.target)) return;
    if (panelRef.current.contains(e.target)) return;

    setShowPanel(false);
  };

  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  // 비밀번호변경
  const showChangePassword = () => {
    setShowPanel();
    modal.show(ChangePasswordModal);
  };

  // 유저정보수정
  const showModifyUserInfo = () => {
    setShowPanel();
    modal.show(ModifyUserInfoModal);
  };

  // 로그아웃
  const doLogout = () => {
    setShowPanel();
    modal.show(LogoutModal);
  };

  return (
    <div className={cx('container')}>
      <div ref={thumbRef} className={cx('thumb')} onClick={togglePanel}>
        {user?.name[0]}
      </div>
      <div ref={panelRef} className={cx(['panel', { show: showPanel }])}>
        <div className={cx('top')}>
          <div className={cx('name')}>{user?.name}</div>
          <div className={cx('email')}>{user?.email}</div>
        </div>
        <div className={cx('line')} />
        <div className={cx('buttons')}>
          <div onClick={showModifyUserInfo} className={cx('button')}>
            <MdOutlinePermIdentity size={20} color="white" />
          </div>
          <div onClick={showChangePassword} className={cx('button')}>
            <MdOutlineKey size={20} color="white" />
          </div>
          <div onClick={doLogout} className={cx('button')}>
            <MdOutlineLogout size={20} color="white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCircle;
