import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState, useMemo } from 'react';

import styles from './Body.module.scss';

import { Attitude, Cell, EKFStatus, Row, Tabs, Vibration } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';
import { getDistance } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Body = ({ robotId }) => {
  const [armState, setArmState] = useState();
  const [globalPosition, setGlobalPosition] = useState();
  const [vfrHud, setVfrHud] = useState();
  const [imageCount, setImageCount] = useState(0);
  const [homePosition, setHomePosition] = useState();
  const [sysState, setSysState] = useState();

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => setArmState(data.isArm ? 'ARM' : 'DISARM'))
    );
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/globalPosition`, setGlobalPosition));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/vfrHud`, setVfrHud));
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/cameraFeedback`, (data) => setImageCount(data.imgIdx))
    );
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/homePosition`, setHomePosition));
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/extendedSysState`, (data) => {
        let vtolState;
        if (data.isFixedWing) {
          vtolState = 'Fixed-wing';
        } else if (data.isMulticopter) {
          vtolState = 'Multicopter';
        }

        setSysState({
          vtolState,
          landedState: data.landedState,
        });
      })
    );

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  const distance = useMemo(() => {
    if (!globalPosition) return 0;
    if (!homePosition) return 0;

    return getDistance(globalPosition, homePosition);
  }, [globalPosition, homePosition]);

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <Row label="Arm State" value={armState} valueStyle={armState === 'DISARM' && { color: 'red' }} />
        <Row label="VTOL State" value={sysState?.vtolState} />
        <Row label="Landed State" value={sysState?.landedState} />
        <Row label="Distance to Home" value={commaNumber(distance.toFixed(2))} unit="m" />
        {imageCount > 0 && <Row label="Shoot Count" value={commaNumber(imageCount)} />}
      </div>
      <div className={cx('boxes')}>
        <Cell
          data={[
            {
              title: 'Throttle',
              value: vfrHud ? vfrHud.throttle.toFixed(1) : 0,
              unit: '%',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Relative Altitude',
              value: globalPosition ? commaNumber(globalPosition.ralt.toFixed(2)) : 0,
              unit: 'm',
            },
            {
              title: 'MSL Altitude',
              value: globalPosition ? commaNumber(globalPosition.alt.toFixed(2)) : 0,
              unit: 'm',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Airspeed',
              value: vfrHud ? commaNumber(vfrHud.airspeed.toFixed(2)) : 0,
              unit: 'm/s',
            },
            {
              title: 'Ground Speed',
              value: vfrHud ? commaNumber(vfrHud.groundspeed.toFixed(2)) : 0,
              unit: 'm/s',
            },
            {
              title: 'Vertical Speed',
              value: vfrHud ? commaNumber(vfrHud.climb.toFixed(2)) : 0,
              unit: 'm/s',
            },
          ]}
        />
      </div>
      <Tabs tabs={['Attitude', 'EKF Status', 'Vibration']}>
        <Attitude data={robotId} className={cx('attitude')} />
        <EKFStatus data={robotId} />
        <Vibration data={robotId} />
      </Tabs>
    </div>
  );
};

export default Body;
