import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const message = useMemo(() => {
    switch (missionItem.data.enable) {
      case 0:
        return 'Take control back from an external controller.';
      case 1:
        return 'Hand control over to an external controller.';
      default:
        return;
    }
  }, [missionItem.data.enable]);

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>{message}</div>
    </div>
  );
};

export default Item;
