import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Controller.module.scss';

import actions from '@/actions';
import MODELS from '@/models';

const cx = classNames.bind(styles);

const Controller = () => {
  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robot.robots);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const enableCommandId = useSelector((state) => state.command.enableCommandId);
  const [show, setShow] = useState(enableCommandId !== null);

  const targetRobots = useMemo(() => {
    return robots.filter((robot) => targetRobotIds.includes(robot.id));
  }, [targetRobotIds, robots]);

  const Component = useMemo(() => {
    try {
      const [modelId, groupName, commandName] = enableCommandId.split('/');
      const group = MODELS[modelId].commandGroups.find(({ name }) => name === groupName);
      const command = group.commands.find(({ name }) => name === commandName);
      return command.component;
    } catch (e) {
      return;
    }
  }, [enableCommandId]);

  useUpdateEffect(() => {
    if (enableCommandId) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [enableCommandId]);

  useUpdateEffect(() => {
    close();
  }, [targetRobotIds]);

  const close = () => {
    dispatch(actions.command.reset());
  };

  if (!enableCommandId) return;

  return (
    <div className={cx(['container', { show }])}>
      <div className={cx('close')} onClick={close}>
        <RiCloseLine size={16} color="white" />
      </div>
      {targetRobots.length > 1 && (
        <div className={cx('wrapper')}>
          <div className={cx('message')}>Command the {targetRobots.length} robots below.</div>
          <div className={cx('robots')}>
            {targetRobots.map((robot) => (
              <div key={robot.id} className={cx('item')}>
                <div className={cx('thumbnail')}>
                  <img src={robot.thumbnailUrl ?? robot.model.thumbnailUrl} width="100%" alt={robot.model.name} />
                </div>
                <div className={cx('name')}>{robot.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Component data={targetRobots} />
    </div>
  );
};

export default Controller;
