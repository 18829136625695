import { degreesToRadians, radiansToDegrees } from '@turf/turf';
import classNames from 'classnames/bind';
import React, { useMemo, useRef, useEffect } from 'react';
import { RiCheckLine } from 'react-icons/ri';
import { useDispatch, useStore } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import MissionItemRow from '@/components/ui/MissionItemRow';
import {
  SURVEY_GAP_MIN,
  SURVEY_GAP_MAX,
  SURVEY_TURNAROUND_MIN,
  SURVEY_TURNAROUND_MAX,
  OVERLAP_DEFAULT_OPTIONS,
  OVERLAP_MIN,
  OVERLAP_MAX,
} from '@/config';
import { CAMERAS } from '@/define/camera';
import { NotifierService as notifier } from '@/libs/Notifier';
import { getDistance, getDistanceAlongPath } from '@/utils/MapUtils';
import { deepCopy, nestedAssign } from '@/utils/ObjectUtils';
import { getPositions } from '@/utils/SurveyCalculator';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const domRefs = useRef({
    relAltitude: useRef(),
    gap: useRef(),
    turnaround: {
      before: useRef(),
      after: useRef(),
    },
    rotate: useRef(),
    segmentDistance: useRef(),
    camera: useRef(),
    overlap: {
      forward: useRef(),
      side: useRef(),
    },
  }).current;

  // 경로 분할 사용여부
  const hasSegment = useMemo(() => {
    return missionItem.data.segment !== null;
  }, [missionItem.data.segment]);

  // 카메라 사용여부
  const hasCamera = useMemo(() => {
    return missionItem.data.camera !== null;
  }, [missionItem.data.camera]);

  // 전체 경로 거리
  const totalDistance = useMemo(() => {
    return getDistanceAlongPath(missionItem.data.positions);
  }, [missionItem.data.positions]);

  // 분할 경로 내역
  const segments = useMemo(() => {
    if (!hasSegment) return;

    const positionGroups = missionItem.data.positions.reduce((acc, position) => {
      const { segment } = position;

      if (!acc[segment]) {
        acc[segment] = [];
      }
      acc[segment].push(position);

      return acc;
    }, []);

    const distances = positionGroups.map((positions) => getDistanceAlongPath(positions));

    return distances.map((distance, index) => {
      // 누적 경로 거리
      let accumulatedDistance = 0;
      for (let i = 0; i < index; i++) {
        accumulatedDistance += distances[i];
      }
      const left = `${(accumulatedDistance / totalDistance) * 100}%`;
      let right = `${(1 - (accumulatedDistance + distance) / totalDistance) * 100}%`;
      if (distances.length - 1 === index) {
        right = '0%';
      }

      return {
        label: `${index + 1}`.padStart(3, '0'),
        distance,
        left,
        right,
      };
    });
  }, [missionItem.data.segment]);

  // 경로 분할 거리 최소값, 최대값 정의
  const segmentDistanceRange = useMemo(() => {
    if (!hasSegment) return;

    // 최소값: 단위 경로별 거리 중 최대값
    const min = missionItem.data.positions.slice(1).reduce((max, position, index) => {
      const distance = getDistance(missionItem.data.positions[index], position);
      return Math.max(max, distance);
    }, 0);
    // 최대값: 전체 경로 거리
    const max = totalDistance;

    return { min, max };
  }, [hasSegment]);

  // 촬영 수
  const shootCount = useMemo(() => {
    return store.getState().editor.shoots.filter(({ id }) => id.startsWith(missionItem.id)).length;
  }, [missionItem.data]);

  useEffect(() => {
    setFieldsToOrigin();
  }, [missionItem.data]);

  const setFieldsToOrigin = () => {
    domRefs.relAltitude.current.value = missionItem.data.altitude.toFixed(1);
    domRefs.gap.current.value = missionItem.data.gap.toFixed(1);
    domRefs.turnaround.before.current.value = missionItem.data.turnaround.before.toFixed(1);
    domRefs.turnaround.after.current.value = missionItem.data.turnaround.after.toFixed(1);
    domRefs.rotate.current.value = missionItem.data.rotate;

    if (hasSegment) {
      domRefs.segmentDistance.current.value = missionItem.data.segment.distance.toFixed(1);
    } else {
      domRefs.segmentDistance.current.value = '';
    }

    if (hasCamera) {
      domRefs.camera.current.value = missionItem.data.camera.name;
      domRefs.overlap.forward.current.value = missionItem.data.camera.options.overlap.forward.toFixed(1);
      domRefs.overlap.side.current.value = missionItem.data.camera.options.overlap.side.toFixed(1);
    } else {
      domRefs.camera.current.value = '';
    }
  };

  const changeRelAltitude = (e) => {
    const altitude = Number(e.target.value);

    if (hasCamera) {
      const camera = deepCopy(missionItem.data.camera);
      camera.interval = getDistanceByOptions(altitude, camera.options.aov.vertical, camera.options.overlap.forward);

      const gap = getDistanceByOptions(
        altitude,
        missionItem.data.camera.options.aov.horizontal,
        missionItem.data.camera.options.overlap.side
      );
      const positions = getPositionsByChangedOption({ gap });

      editSurvey({ altitude, gap, positions, camera });
    } else {
      editSurvey({ altitude });
    }
  };

  const changeGap = (e) => {
    let gap = Number(e.target.value);
    gap = Math.max(gap, SURVEY_GAP_MIN);
    gap = Math.min(gap, SURVEY_GAP_MAX);

    const positions = getPositionsByChangedOption({ gap });
    editSurvey({ gap, positions });
  };

  const changeTurnaround = (e) => {
    const name = e.target.name.split('.')[1];

    let value = Number(e.target.value);
    value = Math.max(value, SURVEY_TURNAROUND_MIN);
    value = Math.min(value, SURVEY_TURNAROUND_MAX);

    const turnaround = deepCopy(missionItem.data.turnaround);
    turnaround[name] = value;
    const positions = getPositionsByChangedOption({ turnaround });
    editSurvey({ turnaround, positions });
  };

  const changeRotate = (e) => {
    let rotate = Number(e.target.value);
    rotate = parseInt(rotate) % 360;
    if (rotate < 0) {
      rotate = 360 - Math.abs(rotate);
    }
    rotate %= 180;

    const positions = getPositionsByChangedOption({ rotate });
    editSurvey({ rotate, positions });
  };

  const changeSegmentDistance = (e) => {
    let distance = Number(e.target.value);
    distance = Math.max(distance, segmentDistanceRange.min);
    distance = Math.min(distance, segmentDistanceRange.max);

    const segment = { index: 0, distance };
    const positions = getPositionsByChangedOption({ segment });
    editSurvey({ positions, segment });
  };

  const selectSegment = (index) => {
    const segment = { ...missionItem.data.segment, index };
    editSurvey({ segment });
  };

  const changeCamera = (camera) => {
    const aov = {
      vertical: getAov(camera.specs.sensorSize.height, camera.specs.focalLength),
      horizontal: getAov(camera.specs.sensorSize.width, camera.specs.focalLength),
    };
    const interval = getDistanceByOptions(missionItem.data.altitude, aov.vertical, OVERLAP_DEFAULT_OPTIONS.forward);
    const gap = getDistanceByOptions(missionItem.data.altitude, aov.horizontal, OVERLAP_DEFAULT_OPTIONS.side);
    const positions = getPositionsByChangedOption({ gap, camera });

    editSurvey({
      gap,
      positions,
      camera: {
        name: camera.name,
        interval,
        options: {
          ...camera.specs,
          aov,
          overlap: OVERLAP_DEFAULT_OPTIONS,
        },
      },
    });
  };

  const selectCamera = (e) => {
    const found = CAMERAS.find(({ name }) => name === e.target.value);
    changeCamera(found);
  };

  const changeOverlap = (e) => {
    const name = e.target.name;

    let value = Number(e.target.value);
    value = Math.max(value, OVERLAP_MIN);
    value = Math.min(value, OVERLAP_MAX);

    const camera = deepCopy(missionItem.data.camera);
    nestedAssign(camera.options, name.split('.'), value);

    if (name.endsWith('forward')) {
      camera.interval = getDistanceByOptions(
        missionItem.data.altitude,
        camera.options.aov.vertical,
        camera.options.overlap.forward
      );
    }

    const gap = getDistanceByOptions(
      missionItem.data.altitude,
      camera.options.aov.horizontal,
      camera.options.overlap.side
    );
    const positions = getPositionsByChangedOption({ gap });

    editSurvey({ gap, positions, camera });
  };

  const editSurvey = (values) => {
    if (values.positions?.length === 0) {
      notifier.error('The area is too small.');
      setFieldsToOrigin();
      return;
    }

    // 경로 분할 중 경로 분할 관련 외 값 수정 시
    if (hasSegment && !Object.hasOwn(values, 'segment')) {
      notifier.error('Please deactivate the segment option to edit survey.');
      setFieldsToOrigin();
      return;
    }

    dispatch(actions.editor.editSurvey(index, values));
  };

  const getAov = (sensorSize, focalLength) => {
    const radian = 2 * Math.atan(sensorSize / (focalLength * 2));
    return radiansToDegrees(radian);
  };

  const getPositionsByChangedOption = (values) => {
    return getPositions(missionItem.data.boundary, {
      gap: missionItem.data.gap,
      turnaround: missionItem.data.turnaround,
      rotate: missionItem.data.rotate,
      camera: missionItem.data.camera,
      ...values,
    });
  };

  const getDistanceByOptions = (altitude, aov, overlap) => {
    const distance = Math.tan(degreesToRadians(aov / 2)) * altitude * 2;
    const value = distance * (1 - overlap / 100);

    return Number(value.toFixed(1));
  };

  const toggleSegment = () => {
    let segment = null;
    if (!hasSegment) {
      const halfIndex = missionItem.data.positions.length / 2;
      const distance = getDistanceAlongPath(missionItem.data.positions.slice(0, halfIndex + 1));

      segment = { index: 0, distance };
    }

    const positions = getPositionsByChangedOption({ segment });
    editSurvey({ positions, segment });
  };

  const toggleCamera = () => {
    if (hasCamera) {
      const positions = getPositionsByChangedOption({ camera: null });
      editSurvey({ positions, camera: null });
    } else {
      changeCamera(CAMERAS[0]);
    }
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Relative Altitude" unit="m">
        <input
          ref={domRefs.relAltitude}
          name="relAltitude"
          type="number"
          onBlur={changeRelAltitude}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Gap" unit="m">
        <input
          ref={domRefs.gap}
          name="gap"
          type="number"
          readOnly={hasCamera}
          onBlur={changeGap}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Before turnaround" unit="m">
        <input
          ref={domRefs.turnaround.before}
          name="turnaround.before"
          type="number"
          onBlur={changeTurnaround}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="After turnaround" unit="m">
        <input
          ref={domRefs.turnaround.after}
          name="turnaround.after"
          type="number"
          onBlur={changeTurnaround}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Rotate" unit="°">
        <input
          ref={domRefs.rotate}
          name="rotate"
          type="number"
          onBlur={changeRotate}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Segment" unit="m">
        <Checkbox checked={hasSegment} onClick={toggleSegment} />
        <input
          ref={domRefs.segmentDistance}
          name="segment.distance"
          type="number"
          disabled={!hasSegment}
          onBlur={changeSegmentDistance}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      {hasSegment && (
        <div className={cx('segments')}>
          {segments.map((segment, index) => {
            const { label, distance, left, right } = segment;
            const isChecked = index === missionItem.data.segment.index;

            return (
              <div key={index} className={cx('row')}>
                <label>
                  {label}
                  <div className={cx('gauge')}>
                    <div className={cx(['bar', { active: isChecked }])} style={{ left, right }} />
                  </div>
                  <div className={cx('wrapper')}>
                    <div className={cx('radio')} onClick={() => selectSegment(index)}>
                      {isChecked && <RiCheckLine size={10} color="white" />}
                    </div>
                    <input value={distance.toFixed(1)} readOnly className={cx('value')} />
                    <span className={cx('unit')}>m</span>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      )}
      <MissionItemRow label="Camera">
        <Checkbox checked={hasCamera} onClick={toggleCamera} />
        <select ref={domRefs.camera} disabled={!hasCamera} onChange={selectCamera}>
          {!hasCamera && <option value="">None</option>}
          {CAMERAS.map((camera, index) => (
            <option key={index} value={camera.name}>
              {camera.name}
            </option>
          ))}
        </select>
      </MissionItemRow>
      {hasCamera && (
        <>
          <MissionItemRow label="Sensor Size" unit="㎜">
            <input
              type="text"
              value={`${missionItem.data.camera.options.sensorSize.width} x ${missionItem.data.camera.options.sensorSize.height}`}
              readOnly
            />
          </MissionItemRow>
          <MissionItemRow label="Focal Length" unit="㎜">
            <input type="number" value={missionItem.data.camera.options.focalLength} readOnly />
          </MissionItemRow>
          <MissionItemRow label="Forward Overlap" unit="%">
            <input
              ref={domRefs.overlap.forward}
              name="overlap.forward"
              type="number"
              onBlur={changeOverlap}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </MissionItemRow>
          <MissionItemRow label="Side Overlap" unit="%">
            <input
              ref={domRefs.overlap.side}
              name="overlap.side"
              type="number"
              onBlur={changeOverlap}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </MissionItemRow>
          <MissionItemRow label="Interval" unit="m">
            <input type="number" value={missionItem.data.camera.interval} readOnly />
          </MissionItemRow>
          <MissionItemRow label="Shoot Count">
            <input type="number" value={shootCount} readOnly />
          </MissionItemRow>
        </>
      )}
    </div>
  );
};

export default MissionItem;
