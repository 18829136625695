import classNames from 'classnames/bind';
import React from 'react';

import ClickPoint from './01_ClickPoint';
import DrawStraightLine from './02_DrawStraightLine';
import DrawCurvedLine from './03_DrawCurvedLine';
import DrawArea from './04_DrawArea';
import DrawPolygon from './05_DrawPolygon';
import styles from './index.module.scss';

import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const DrawingToolbar = () => {
  return (
    <div className={cx('container')}>
      <ClickPoint />
      <Bar />
      <DrawStraightLine />
      <Bar />
      <DrawCurvedLine />
      <Bar />
      <DrawArea />
      <Bar />
      <DrawPolygon />
    </div>
  );
};

export default DrawingToolbar;
