import classNames from 'classnames/bind';
import React, { Children, useState } from 'react';

import styles from './Tabs.module.scss';

const cx = classNames.bind(styles);

const Tabs = ({ tabs, children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const selectTab = (e) => {
    e.stopPropagation();
    setActiveIndex(Number(e.target.dataset.index));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('tabs')}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            data-index={index}
            className={cx(['tab', { active: index === activeIndex }])}
            onClick={selectTab}>
            {tab}
          </div>
        ))}
      </div>
      {Children.map(children, (child, index) => (
        <div key={index} className={cx(['pane', { active: index === activeIndex }])}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
