import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { MdOutlineNotifications, MdOutlineClose } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import styles from './Notifications.module.scss';

import actions from '@/actions';
import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const Notifications = () => {
  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robot.robots);
  const notifications = useSelector((state) => state.notification.unread);

  const latest = useMemo(() => {
    if (notifications.length === 0) return;

    return notifications[notifications.length - 1];
  }, [notifications]);

  const robot = useMemo(() => {
    return robots.find((robot) => robot.id === latest?.robotId);
  }, [notifications]);

  const doRead = (e) => {
    const id = e.target.closest('[data-id]').dataset.id;
    dispatch(actions.notification.read(id));
  };

  const closeAll = (e) => {
    e.stopPropagation();

    dispatch(actions.notification.readAll());
  };

  if (!latest) return;

  return (
    <div className={cx('container')} data-id={latest.id} onClick={doRead}>
      <div className={cx('head')}>
        <MdOutlineNotifications size={16} color="white" />
        <div className={cx('count')}>{notifications?.length ?? 0}</div>
      </div>
      <Bar />
      <div className={cx('body')}>
        <div className={cx('time')}>{latest.time}</div>
        <div className={cx('robot')}>
          <div className={cx('color')} style={{ backgroundColor: robot.color }} />
          <div className={cx('name')}>{robot.name}</div>
        </div>
        <div className={cx('text')}>{latest.text}</div>
        <div className={cx('close')} onClick={closeAll}>
          <MdOutlineClose size={14} color="white" />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
