import useMountEffect from '@restart/hooks/useMountEffect';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import MissionListModal, { PURPOSE } from '@/components/modals/MissionList';
import { ModalService as modal } from '@/libs/Modal';

const Action = () => {
  const dispatch = useDispatch();

  useMountEffect(() => {
    modal.show(MissionListModal, { purpose: PURPOSE.UPLOAD });
    dispatch(actions.command.toggle());
  });

  return null;
};

export default Action;
