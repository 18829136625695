import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { RiLoader2Line, RiCheckboxCircleFill } from 'react-icons/ri';
import { useDispatch, useStore } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';
import MissionConverter from '@/helpers/MissionConverter';
import EventEmitter from '@/libs/EventEmitter';
import { NotifierService as notifier } from '@/libs/Notifier';
import sleep from '@/utils/Sleep';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { publishCommand } = useContext(MessageContext);
  const [status, setStatus] = useState(0); // 0: Ready, 1: Uploading, 2: Uploaded
  const [subscribeTokens, setSubscribeTokens] = useState([]);

  useMountEffect(() => {
    const notLoadedRobot = robots.find(({ id }) => {
      const missionItems = store.getState().mission[id];
      // FC 다운로드 미션은 elevation 미존재
      return !missionItems || !Object.hasOwn(missionItems[0].data, 'elevation');
    });

    if (notLoadedRobot) {
      notifier.error(`Please load the mission to the ${notLoadedRobot.name} robot first.`);
      dispatch(actions.command.toggle());
    }
  });

  useUpdateEffect(() => {
    // 상태 메시지 수신 완료
    if (subscribeTokens.length === 0) {
      setStatus(2);
      sleep(1000).then(() => dispatch(actions.command.toggle()));
    }
    // 상태 메시지 수신 대기 중
    else if (0 < subscribeTokens.length) {
      setStatus(1);
    }
  }, [subscribeTokens]);

  const doCommand = () => {
    robots.forEach((robot) => {
      // 미션 업로드 결과 상태 메시지 처리
      const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/statustext`, ({ text }) => {
        // 미션 업로드 성공 시
        if (text === 'Flight plan received') {
          const nextSubscribeTokens = subscribeTokens.filter((token) => token !== subscribeToken);
          setSubscribeTokens(nextSubscribeTokens);
        }
        // TODO: 미션 업로드 실패 시
      });
      setSubscribeTokens([...subscribeTokens, subscribeToken]);

      const missionItems = store.getState().mission[robot.id];
      const messages = MissionConverter.ArduPilot.toMavLink(missionItems);
      publishCommand(robot, 'mission/send', [[messages.length], messages, [0], [0]]);
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['slider', { visible: status === 0 }])}>
        <ConfirmSlider onConfirm={doCommand} />
      </div>
      {status === 1 && (
        <div className={cx('status')}>
          <RiLoader2Line size={24} color="white" className={cx('spin')} />
          Uploading...
        </div>
      )}
      {status === 2 && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={24} color="#41a3ff" />
          Uploaded!
        </div>
      )}
    </div>
  );
};

export default Action;
