import classNames from 'classnames/bind';
import React from 'react';
import { RiTreasureMapLine, RiVideoLine, RiHistoryLine } from 'react-icons/ri';

import styles from './TopBar.module.scss';

import OperationHistoryModal from '@/components/modals/OperationHistory';
import AccountCircle from '@/components/ui/AccountCircle';
import Bar from '@/components/ui/Bar';
import MapSelector from '@/components/ui/map/MapSelector';
import ZonesSelector from '@/components/ui/map/ZonesSelector';
import { isBrowser, isElectron } from '@/environment';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const TopBar = () => {
  const openMissionHub = () => {
    if (isBrowser) {
      window.open('/mission-hub/new');
    } else if (isElectron) {
      window.electron.ipcRenderer.send('openMissionHub');
    }
  };

  const openSmatiiView = () => {
    window.open('https://gimcheon.smatii.com/');
  };

  const showOperationHistory = () => {
    modal.show(OperationHistoryModal);
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['side', 'left'])}>
        <img src={require('@/assets/images/logo.png')} alt="M1UCS" />
      </div>
      <div className={cx('center')}>
        <MapSelector />
        {process.env.REACT_APP_USE_ZONE_VWORLD === 'true' && (
          <>
            <Bar height={20} />
            <ZonesSelector />
          </>
        )}
      </div>
      <div className={cx(['side', 'right'])}>
        <div className={cx('menus')}>
          <div onClick={openMissionHub} className={cx('menu')}>
            <RiTreasureMapLine size={20} color="white" />
            <div className={cx('name')}>Mission Hub</div>
          </div>
          {process.env.REACT_APP_USE_SMATIIVIEW_LINK === 'true' && (
            <>
              <Bar height={20} />
              <div onClick={openSmatiiView} className={cx('menu')}>
                <RiVideoLine size={20} color="white" />
                <div className={cx('name')}>G-Viewer</div>
              </div>
            </>
          )}
          <Bar height={20} />
          <div onClick={showOperationHistory} className={cx('menu')}>
            <RiHistoryLine size={20} color="white" />
            <div className={cx('name')}>History</div>
          </div>
        </div>
        <AccountCircle />
      </div>
    </div>
  );
};

export default TopBar;
