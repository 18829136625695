import classNames from 'classnames/bind';
import { fromLonLat } from 'ol/proj';
import React from 'react';
import { RiFullscreenLine, RiZoomOutLine, RiZoomInLine, RiCrosshairLine, RiScreenshot2Line } from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './MapToolbar.module.scss';

import actions from '@/actions';
import Bar from '@/components/ui/Bar';
import { OL_MAX_ZOOM } from '@/config';
import OlMap from '@/helpers/OlMap';
import CoordUtils from '@/utils/CoordUtils';

const cx = classNames.bind(styles);

const MapToolbar = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const zoom = useSelector((state) => state.map.zoom);
  const isShowShoots = useSelector((state) => state.map.isShowShoots);

  const toggleShoots = () => {
    dispatch(actions.map.toggleShoots());
  };

  const fitBounds = () => {
    const { missionItems, guideMissions } = store.getState().editor;

    const positionsFromMissionItems = getPositions(missionItems);
    const positionsFromGuideMissions = guideMissions.flatMap(({ path }) =>
      path.map(({ position }) => CoordUtils.arrayFromObject(position))
    );

    OlMap.fitBounds([...positionsFromMissionItems, ...positionsFromGuideMissions]);
  };

  const getPositions = (missionItems) => {
    const positions = [];
    missionItems.forEach(({ type, data }) => {
      switch (type) {
        case 'navLand':
        case 'navLoiterTime':
        case 'navLoiterToAlt':
        case 'navLoiterTurns':
        case 'navTakeoff':
        case 'navWaypoint':
          positions.push([data.position.lng, data.position.lat]);
          break;

        case 'cusSurvey':
          data.positions.forEach(({ lat, lng }) => positions.push([lng, lat]));
          break;

        default:
          break;
      }
    });
    return positions;
  };

  const zoomOut = () => {
    const view = OlMap.getMap().getView();
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 300,
    });
  };

  const zoomIn = () => {
    const view = OlMap.getMap().getView();
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 300,
    });
  };

  const moveToCurrent = () => {
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      const view = OlMap.getMap().getView();
      view.setZoom(OL_MAX_ZOOM);
      view.setCenter(fromLonLat([coords.longitude, coords.latitude]));
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('group')}>
        <div className={cx(['button', { active: isShowShoots }])} onClick={toggleShoots}>
          <RiScreenshot2Line size={18} title={isShowShoots ? 'Hide Shoots' : 'Show Shoots'} />
        </div>
        <Bar />
        <div className={cx('button')} onClick={fitBounds}>
          <RiFullscreenLine size={18} title="Fit Bounds" />
        </div>
        <Bar />
        <div className={cx('button')} onClick={moveToCurrent}>
          <RiCrosshairLine size={18} title="Current Location" />
        </div>
      </div>
      <div className={cx('group')}>
        <div className={cx('button')} onClick={zoomOut}>
          <RiZoomOutLine size={18} title="Zoom Out" />
        </div>
        <Bar />
        <div className={cx('zoom')}>{Math.round(zoom)}</div>
        <Bar />
        <div className={cx('button')} onClick={zoomIn}>
          <RiZoomInLine size={18} title="Zoom In" />
        </div>
      </div>
    </div>
  );
};

export default MapToolbar;
