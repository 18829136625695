import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { RiLoader2Line, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from './ProcessingButton.module.scss';

import sleep from '@/utils/Sleep';

const cx = classNames.bind(styles);

const ProcessingButton = ({ text, doProcess, className }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState();

  const doStart = () => {
    setIsProcessing(true);

    doProcess().then((success) => {
      setIsProcessing(false);
      setIsSuccess(success);

      sleep(1000).then(() => setIsSuccess());
    });
  };

  return (
    <div className={cx(['container', className])}>
      {!isProcessing && isSuccess === undefined && (
        <button type="button" className={cx('button')} onClick={doStart}>
          {text}
        </button>
      )}
      {isProcessing && (
        <div className={cx('status')}>
          <RiLoader2Line size={20} color="white" className={cx('spin')} />
          <div className={cx('text')}>Processing...</div>
        </div>
      )}
      {!isProcessing && isSuccess && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="green" className={cx('message')} />
          <div className={cx('text')}>Success!</div>
        </div>
      )}
      {!isProcessing && isSuccess === false && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="red" className={cx('message')} />
          <div className={cx('text')}>Failure!</div>
        </div>
      )}
    </div>
  );
};

export default ProcessingButton;
