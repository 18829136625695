import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const MissionItem = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Send the vehicle back to the launch position.</div>
    </div>
  );
};

export default MissionItem;
