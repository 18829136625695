export const deepCopy = (source) => {
  // Null
  if (source === null) {
    return null;
  }
  // Array
  else if (Array.isArray(source)) {
    return source.map((object) => deepCopy(object));
  }
  // Object
  else if (typeof source === 'object') {
    const copied = {};

    Object.keys(source).forEach((key) => {
      copied[key] = deepCopy(source[key]);
    });

    return copied;
  }

  return source;
};

export const nestedAssign = (object, properties, value) => {
  if (properties.length > 1) {
    nestedAssign(object[properties[0]], properties.slice(1), value);
  } else {
    // 값이 undefined 인 경우 필드 제거
    if (value === undefined) {
      delete object[properties[0]];
    } else {
      object[properties[0]] = value;
    }
  }
};
