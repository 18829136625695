import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    distance: useRef(),
  }).current;

  useEffect(() => {
    domRefs.distance.current.value = missionItem.data.distance.toFixed(1);
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Math.max(0, Number(e.target.value));
    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Distance" unit="m">
        <input
          ref={domRefs.distance}
          name="distance"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
