import classNames from 'classnames/bind';
import React from 'react';
import { RiFlightTakeoffLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import styles from './AddTakeoff.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const dispatch = useDispatch();

  const doStart = () => {
    dispatch(actions.editor.changeMode('point'));
    modal.hide();
  };

  return (
    <ModalWrapper disableEscape>
      <div className={cx('icon')}>
        <div className={cx('marker')}>
          <div className={cx('circle')}>
            <div className={cx('label')}>
              <RiFlightTakeoffLine size={16} color="white" />
            </div>
          </div>
          <div className={cx('arrow')} />
        </div>
        <div className={cx('plate')} />
      </div>
      <div className={cx('message')}>Please add a takeoff point.</div>
      <div className={cx('bottom')}>
        <button type="button" className={cx(['button', 'accent'])} onClick={doStart}>
          Start
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
