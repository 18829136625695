import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const type = useMemo(() => {
    switch (missionItem.data.type) {
      case 0:
        return 'Airspeed';
      case 1:
        return 'Ground Speed';
      case 2:
        return 'Ascend Speed';
      case 3:
        return 'Descend Speed';
      default:
        break;
    }
  }, [missionItem.data.type]);

  return (
    <div className={cx('container')}>
      <label>
        Type
        <div className={cx('fieldWrapper')}>
          <input value={type} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Speed
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.speed} readOnly />
          <span className={cx('unit')}>m/s</span>
        </div>
      </label>
      {missionItem.data.throttle && missionItem.data.throttle !== -1 && (
        <label>
          Throttle
          <div className={cx('fieldWrapper')}>
            <input value={missionItem.data.throttle} readOnly />
            <span className={cx('unit')}>%</span>
          </div>
        </label>
      )}
    </div>
  );
};

export default Item;
