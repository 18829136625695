import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ModifyUserInfo.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import API from '@/helpers/API';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Modal = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [info, setInfo] = useState({
    name: user.name,
    cellphone: [
      user.cellphone?.slice(0, 3) ?? '',
      user.cellphone?.slice(3, user.cellphone.length - 4) ?? '',
      user.cellphone?.slice(-4) ?? '',
    ],
  });
  const [isFocusCellphone, setFocusCellphone] = useState(false);

  const nameRef = useRef();
  const cellphoneWrapperRef = useRef();
  const cellphoneRefs = [useRef(), useRef(), useRef()];

  useMountEffect(() => {
    // 이름 입력 필드 포커스
    nameRef.current.focus();
  });

  const close = () => {
    modal.hide();
  };

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.nativeEvent.isComposing) return;

    if (e.code === 'Enter') {
      switch (e.target.name) {
        case 'name':
          cellphoneRefs[0].current.focus();
          break;

        case 'cellphone3':
          doModify();
          break;

        default:
          break;
      }
    }
  };

  const handleChangeCellphone = (e, index) => {
    const { value } = e.target;
    info.cellphone[index] = value;
    setInfo({ ...info, cellphone: [...info.cellphone] });

    // 최대 길이 입력된 경우
    if (value.length === e.target.maxLength) {
      shiftCursor(index + 1);
    }
    // 입력값 없는 경우
    else if (value.length === 0) {
      shiftCursor(index - 1);
    }
  };

  // Cellphone 필드간 커서 이동
  const shiftCursor = (index) => {
    if (index < 0 || index > 2) return;

    cellphoneRefs[index].current.focus();
  };

  // 유효성 검사
  const validAll = () => {
    if (info.name.trim() === '') {
      notifier.error('Please enter your name.');
      nameRef.current.focus();
      return false;
    }
    if (!/^01\d{8,9}$/.test(info.cellphone.join(''))) {
      notifier.error('Please check your cellphone number.');
      cellphoneRefs[0].current.focus();
      return false;
    }
    return true;
  };

  const doModify = () => {
    if (!validAll()) return;

    const data = {
      name: info.name,
      cellphone: info.cellphone.join(''),
    };
    if (data.cellphone === '') {
      data.cellphone = null;
    }

    API.patch('/accounts', data).then(({ success }) => {
      if (success) {
        dispatch(actions.user.modify(data));
        close();
      }
    });
  };

  return (
    <ModalWrapper>
      <div className={cx('fields')}>
        <label>
          Name
          <input
            ref={nameRef}
            name="name"
            type="text"
            maxLength={10}
            autoComplete="off"
            value={info.name}
            onChange={handleChange}
            onKeyDown={pressEnter}
          />
        </label>
        <label>
          Cellphone
          <div
            name="cellphone"
            ref={cellphoneWrapperRef}
            className={cx(['field', 'group', { focus: isFocusCellphone }])}>
            <input
              ref={cellphoneRefs[0]}
              name="cellphone1"
              type="text"
              maxLength={3}
              autoComplete="off"
              value={info.cellphone[0]}
              onFocus={() => setFocusCellphone(true)}
              onBlur={() => setFocusCellphone(false)}
              onChange={(e) => handleChangeCellphone(e, 0)}
            />
            <div className={cx('hyphen')} />
            <input
              ref={cellphoneRefs[1]}
              name="cellphone2"
              type="text"
              maxLength={4}
              autoComplete="off"
              value={info.cellphone[1]}
              onFocus={() => setFocusCellphone(true)}
              onBlur={() => setFocusCellphone(false)}
              onChange={(e) => handleChangeCellphone(e, 1)}
            />
            <div className={cx('hyphen')} />
            <input
              ref={cellphoneRefs[2]}
              name="cellphone3"
              type="text"
              maxLength={4}
              autoComplete="off"
              value={info.cellphone[2]}
              onFocus={() => setFocusCellphone(true)}
              onBlur={() => setFocusCellphone(false)}
              onChange={(e) => handleChangeCellphone(e, 2)}
              onKeyDown={pressEnter}
            />
          </div>
        </label>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doModify}>
          Modify
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
