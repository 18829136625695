import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

import Checkbox from '@/components/ui/Checkbox';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      <label>
        Latitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.position.lat.toFixed(7)} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Longitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.position.lng.toFixed(7)} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      {missionItem.data.parachute && (
        <label>
          Parachute
          <div className={cx('fieldWrapper')}>
            <Checkbox checked={true} />
            <span className={cx('unit')} />
          </div>
        </label>
      )}
    </div>
  );
};

export default Item;
