import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { MdOutlineClose } from 'react-icons/md';

import styles from './NotifierContainer.module.scss';
import { setHandler } from './NotifierService';

const cx = classNames.bind(styles);

const NotifierContainer = ({ duration = 1000 }) => {
  const [notification, setNotification] = useState();
  const [key, setKey] = useState(0);
  const timer = useRef();

  useMountEffect(() => {
    setHandler((type, text) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      setNotification({ type, text });
      setKey((prevKey) => prevKey + 1);

      timer.current = setTimeout(doClose, duration);
    });
  });

  const doClose = () => {
    setNotification();
    clearTimeout(timer.current);
    timer.current = null;
  };

  if (!notification) return;

  return (
    <div className={cx(['container', notification.type])}>
      <div className={cx('content')}>
        <span className={cx('text')}>{notification.text}</span>
        <div className={cx('close')} onClick={doClose}>
          <MdOutlineClose size={16} color="white" />
        </div>
      </div>
      <div key={key} className={cx(['progress', notification.type])} style={{ animationDuration: `${duration}ms` }} />
    </div>
  );
};

export default NotifierContainer;
