import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import ChartJs from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartZoom from 'chartjs-plugin-zoom';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import 'chartjs-plugin-dragdata';
import React, { useState, useRef, useMemo } from 'react';
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { Resizable } from 'react-resizable';

import styles from './Chart.module.scss';

import actions from '@/actions';
import { CHART_MIN_WIDTH, CHART_HEIGHT } from '@/config';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import { getDistance, getDistanceAlongPath, getElevationAlongPath, getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Chart = () => {
  const dispatch = useDispatch();
  const missionItems = useSelector((state) => state.editor.missionItems);
  const path = useSelector((state) => state.editor.path);
  const [showChart, setShowChart] = useState(false);
  const [width, setWidth] = useState(CHART_MIN_WIDTH);
  const [maxWidth, setMaxWidth] = useState();
  const chartRef = useRef();
  const chart = useRef();
  const range = { min: useRef(), max: useRef() };

  const waypoints = useMemo(() => {
    const draftWaypoints = [];
    let labelNumber = 1;

    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
          draftWaypoints.push({ ...missionItem, label: labelNumber });

          // Parachute 항목 체크한 경우
          if (missionItem.data.parachute) {
            labelNumber += 3; // Waypoint, Parachute Enable, Release 반영
          }
          break;

        case 'navLoiterTime':
        case 'navLoiterToAlt':
        case 'navLoiterTurns':
        case 'navTakeoff':
          draftWaypoints.push({ ...missionItem, label: labelNumber });
          break;

        case 'navWaypoint':
          draftWaypoints.push({ ...missionItem, label: labelNumber });

          // Speed 항목 체크한 경우
          if (missionItem.data.speed) {
            labelNumber++; // Change Speed 반영
          }
          break;

        case 'navReturnToLaunch':
          draftWaypoints.push({ ...missionItem, label: labelNumber, data: missionItems[0].data });
          break;

        case 'cusSurvey':
          {
            const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
            const startLabel = labelNumber;
            const endLabel = labelNumber + surveyMissionItem.data.positions.length - 1;

            // 서베이 시작점
            draftWaypoints.push({
              ...missionItem,
              id: `${missionItem.id}/start`,
              label: startLabel,
              data: {
                position: surveyMissionItem.data.positions.at(0),
                altitude: missionItem.data.altitude,
              },
            });

            // 서베이 종료점
            draftWaypoints.push({
              ...missionItem,
              id: `${missionItem.id}/end`,
              label: endLabel,
              data: {
                position: surveyMissionItem.data.positions.at(-1),
                altitude: missionItem.data.altitude,
              },
            });

            labelNumber = endLabel;
          }
          break;

        default:
          break;
      }

      labelNumber++;
    });

    return draftWaypoints;
  }, [missionItems]);

  useMountEffect(() => {
    ChartJs.register(ChartZoom);
    ChartJs.register(ChartDataLabels);
  });

  useMountEffect(() => {
    const resizeWindow = () => {
      const offset = 280;
      setMaxWidth(window.innerWidth - offset);
    };

    resizeWindow();
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  });

  useUpdateEffect(() => {
    // 윈도우 리사이즈에 의해 변경된 최대 너비에 따른 차트 너비 조정
    if (width < maxWidth) return;
    if (maxWidth < CHART_MIN_WIDTH) return;

    setWidth(maxWidth);
  }, [maxWidth]);

  useUpdateEffect(() => {
    if (waypoints.length === 0) {
      setShowChart(false);
      return;
    }

    drawChart();
    setShowChart(true);
  }, [waypoints]);

  const drawChart = async () => {
    let elevations;
    if (path.length === 1) {
      elevations = [await getElevation(path[0].position)];
    } else {
      elevations = await getElevationAlongPath(path.map(({ position }) => position));
    }
    const chartData = getChartData(elevations);

    const mslAltitudes = waypoints.map(({ data }) => waypoints[0].data.elevation + data.altitude);
    const minValue = Math.ceil(Math.min(...elevations, ...mslAltitudes));
    const maxValue = Math.ceil(Math.max(...elevations, ...mslAltitudes));
    const margin = (maxValue - minValue) * 0.1; // 10% of the difference
    range.min.current = minValue - margin;
    range.max.current = maxValue + margin;

    // 차트 존재 시
    if (chart.current) {
      chart.current.data.labels = chartData.labels;
      chart.current.data.datasets[0].data = chartData.datasets[0].data;
      chart.current.data.datasets[1].data = chartData.datasets[1].data;
      chart.current.options.scales.yAxis.min = range.min.current;
      chart.current.options.scales.yAxis.max = range.max.current;
      chart.current.options.plugins.dragData.onDragStart = handleDragStart;
      chart.current.options.plugins.dragData.onDragEnd = handleDragEnd;
      chart.current.update();
    }
    // 차트 미존재 시
    else {
      const context = chartRef.current.getContext('2d');
      const options = {
        type: 'line',
        data: chartData,
        options: {
          layout: {
            padding: {
              top: 4,
              right: 12,
              bottom: 12,
              left: 8,
            },
          },
          maintainAspectRatio: false,
          scales: {
            xAxis: { display: false },
            yAxis: {
              min: range.min.current,
              max: range.max.current,
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: {
                font: { size: 12 },
                callback: (value) => `${commaNumber(parseInt(value))}m`,
              },
            },
          },
          onHover: (e, points) => {
            if (points.length && points[0].datasetIndex === 0) {
              const point = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false);
              if (point.length) {
                e.native.target.style.cursor = 'grab';
                return;
              }
            }
            e.native.target.style.cursor = 'default';
          },
          plugins: {
            zoom: { zoom: { mode: 'y' } },
            dragData: {
              round: 1,
              onDragStart: handleDragStart,
              onDrag: handleDrag,
              onDragEnd: handleDragEnd,
            },
            tooltip: {
              callbacks: {
                title: () => null, // Tooltip 제목 제거
              },
            },
          },
        },
      };

      chart.current = new ChartJs(context, options);
    }
  };

  const handleDragStart = (e, datasetIndex, index, value) => {
    // Elevation drag 방지
    if (datasetIndex === 1) return false;
    // Survey drag 방지
    if (waypoints[index].type === 'cusSurvey') return false;
  };

  const handleDrag = (e, datasetIndex, index, value) => {
    e.target.style.cursor = 'grabbing';
  };

  const handleDragEnd = (e, datasetIndex, index, value) => {
    e.target.style.cursor = 'default';

    const targetIndex = missionItems.findIndex(({ id }) => id === waypoints[index].id);
    const altitude = value.y - waypoints[0].data.elevation;
    dispatch(actions.editor.editWaypoint(targetIndex, 'altitude', altitude));
  };

  const getChartData = (elevations) => {
    // 총 거리
    const wholeDistance = getDistanceAlongPath(path.map(({ position }) => position));
    // 누적 거리
    let accumulatedDistance = 0;

    return {
      labels: elevations.map((_, index) => index + 1),
      datasets: [
        {
          label: 'MSL Altitude',
          data: waypoints.map((waypoint, index) => {
            let x = 0;
            if (index > 0) {
              const from = waypoints[index - 1].data.position;

              if (waypoint.type === 'cusSurvey' && waypoint.id.includes('/end')) {
                const missionItemId = waypoint.id.split('/')[0];
                const missionItem = missionItems.find(({ id }) => id === missionItemId);
                const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
                accumulatedDistance += getDistanceAlongPath(surveyMissionItem.data.positions);
              } else {
                const to = waypoint.data.position;
                accumulatedDistance += getDistance(from, to);
              }

              if (wholeDistance > 0) {
                x = Math.round((accumulatedDistance / wholeDistance) * elevations.length);
              }
            }

            let y = waypoints[0].data.elevation;
            // Land 경우
            if (waypoint.type === 'navLand') {
              // 직전 경로점 고도 동일
              y += waypoints[index - 1].data.altitude;
            } else {
              y += waypoint.data.altitude;
            }

            return { x, y, label: waypoint.label };
          }),
          fill: true,
          borderColor: '#41a3ff',
          pointStyle: 'circle',
          borderWidth: 1,
          pointBorderWidth: 10,
          pointBackgroundColor: '#41a3ff',
          backgroundColor: '#41a3ff22',
          datalabels: {
            display: true,
            color: 'white',
            font: { size: '9px' },
          },
        },
        {
          label: 'Elevation',
          data: elevations.map((elevation, index) => ({
            x: index + 1,
            y: elevation,
          })),
          fill: true,
          borderColor: 'gold',
          borderWidth: 1,
          pointBorderWidth: 0,
          backgroundColor: '#ffd70022',
          datalabels: { display: false },
        },
      ],
    };
  };

  const resize = (e, { size }) => {
    setWidth(size.width);
  };

  const zoomOut = () => {
    const { yAxis } = chart.current.scales;

    const allValues = [
      ...chart.current.data.datasets[0].data.map(({ y }) => y),
      ...chart.current.data.datasets[1].data.map(({ y }) => y),
    ];
    const minValue = Math.ceil(Math.min(...allValues));
    const maxValue = Math.ceil(Math.max(...allValues));
    const pixelOfGap = yAxis.getPixelForValue(minValue) - yAxis.getPixelForValue(maxValue);

    // Only when exceeding 40px
    if (40 < pixelOfGap) {
      chart.current.zoom({ y: 0.8 }, 300);
    }
  };

  const zoomIn = () => {
    const { yAxis } = chart.current.scales;

    if (yAxis.min < range.min.current && range.max.current < yAxis.max) {
      chart.current.zoom({ y: 1.2 }, 300);
    }
  };

  return (
    <div className={cx(['container', { show: showChart }])}>
      <Resizable
        width={width}
        height={CHART_HEIGHT}
        minConstraints={[CHART_MIN_WIDTH, CHART_HEIGHT]}
        maxConstraints={[maxWidth, CHART_HEIGHT]}
        onResize={resize}
        handle={<div className={cx('handle')} />}>
        <div className={cx('chartWrapper')} style={{ width, height: CHART_HEIGHT }}>
          <canvas ref={chartRef} className={cx('canvas')}></canvas>
          <div className={cx('buttons')}>
            <button className={cx('button')} onClick={zoomOut}>
              <RiZoomOutLine size={14} color="white" />
            </button>
            <button className={cx('button')} onClick={zoomIn}>
              <RiZoomInLine size={14} color="white" />
            </button>
          </div>
        </div>
      </Resizable>
    </div>
  );
};

export default Chart;
