import {
  point,
  envelope,
  lineChunk,
  lineString,
  destination,
  transformRotate,
  degreesToRadians,
  featureCollection,
} from '@turf/turf';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { SURVEY_DEFAULT_OPTIONS } from '@/config';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import CoordUtils from '@/utils/CoordUtils';
import { deepCopy, nestedAssign } from '@/utils/ObjectUtils';

const initialState = {
  id: null,
  specs: {
    protocol: null,
    firmware: null,
    frame: null,
  },
  config: {
    altitude: 100,
    speed: 2,
  },
  mode: null,
  name: '',
  missionItems: [],
  guideMissions: [],
  markers: {},
  path: [],
  labels: [],
  shoots: [],
  needSave: true,
};

export default handleActions(
  {
    'EDITOR/SET_SPECS': (state, action) =>
      produce(state, (draft) => {
        draft.specs = action.payload.specs;
      }),
    'EDITOR/SET_CONFIG': (state, action) =>
      produce(state, (draft) => {
        draft.config = action.payload.config;
      }),
    'EDITOR/CHANGE_MODE': (state, action) =>
      produce(state, (draft) => {
        draft.mode = action.payload.mode;
      }),
    'EDITOR/CHANGE_NAME': (state, action) =>
      produce(state, (draft) => {
        draft.name = action.payload.name;
        draft.needSave = true;
      }),
    'EDITOR/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { id, name, json } = action.payload;
        // ID 존재하는 경우 (Open)
        if (id) {
          draft.id = id;
          draft.needSave = false;
        }
        // ID 존재하지 않는 경우 (Upload)
        else {
          draft.id = uuidv4();
          draft.needSave = true;
        }

        // 1. 사양(Protocol, Firmware, Frame) 정의
        draft.specs = json.specs;

        // 2. 미션명 정의
        draft.name = name;

        // 3. 미션 아이템 정의
        draft.missionItems = json.missionItems;

        // 4. 마커 정의
        draft.markers = getMarkers(json.missionItems);

        // 5. 경로 정의
        const path = getPath(json.missionItems);
        draft.path = path;

        // 6. 라벨 정의
        draft.labels = getPathLabels(path);

        // 7. 촬영 정의
        draft.shoots = getShoots(json.missionItems);
      }),
    'EDITOR/SAVE': (state, action) =>
      produce(state, (draft) => {
        draft.needSave = false;
      }),
    'EDITOR/APPEND_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { waypoint } = action.payload;

        // 1. 미션 아이템 정의
        const type = state.missionItems.length === 0 ? 'navTakeoff' : 'navWaypoint';
        const newMissionItem = {
          id: uuidv4(),
          type,
          data: {
            ...waypoint,
            name: '',
            altitude: state.config.altitude,
          },
        };
        const missionItems = [...state.missionItems, newMissionItem];
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/INSERT_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { index, waypoint } = action.payload;

        // 0. 삽입 위치 탐색
        const id = state.path[index - 1].id.split('/')[0];
        const targetIndex = state.missionItems.findIndex((missionItem) => missionItem.id === id);

        // 1. 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'navWaypoint',
          data: {
            ...waypoint,
            name: '',
            altitude: state.config.altitude,
          },
        };
        const missionItems = [...state.missionItems];
        missionItems.splice(targetIndex + 1, 0, newMissionItem);
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/MOVE_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { id, waypoint } = action.payload;

        const index = state.missionItems.findIndex((missionItem) => missionItem.id === id);
        if (index === -1) return;

        // 1. 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems); // to avoid read-only property
        missionItems[index].data.position = waypoint.position;
        missionItems[index].data.elevation = waypoint.elevation;
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/EDIT_WAYPOINT': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        // 1. 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems);
        nestedAssign(missionItems[index].data, name.split('.'), value);
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        draft.needSave = true;
      }),
    'EDITOR/EDIT_ALL_WAYPOINTS': (state, action) =>
      produce(state, (draft) => {
        if (state.missionItems.length === 0) return;

        const { name, value } = action.payload;

        // Relative Altitude
        if (name === 'relAltitude') {
          draft.missionItems = state.missionItems.map((missionItem) => {
            if (
              ['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navTakeoff', 'navWaypoint', 'cusSurvey'].includes(
                missionItem.type
              )
            ) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value;
              return newMissionItem;
            }
            return missionItem;
          });
        }
        // AGL Altitude
        else if (name === 'aglAltitude') {
          const homeElevation = state.missionItems[0].data.elevation;
          draft.missionItems = state.missionItems.map((missionItem) => {
            if (['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navWaypoint'].includes(missionItem.type)) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value - homeElevation + missionItem.data.elevation;
              return newMissionItem;
            }
            return missionItem;
          });
        }
        // MSL Altitude
        else if (name === 'mslAltitude') {
          const homeElevation = state.missionItems[0].data.elevation;
          draft.missionItems = state.missionItems.map((missionItem) => {
            if (['navLoiterTime', 'navLoiterToAlt', 'navLoiterTurns', 'navWaypoint'].includes(missionItem.type)) {
              const newMissionItem = deepCopy(missionItem);
              newMissionItem.data.altitude = value - homeElevation;
              return newMissionItem;
            }
            return missionItem;
          });
        }

        draft.needSave = true;
      }),
    'EDITOR/APPEND_SURVEY': (state, action) =>
      produce(state, (draft) => {
        const { boundary, positions } = action.payload;

        // 1. 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'cusSurvey',
          data: {
            ...SURVEY_DEFAULT_OPTIONS,
            boundary,
            positions,
            altitude: state.config.altitude,
          },
        };
        const missionItems = [...state.missionItems, newMissionItem];
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/EDIT_SURVEY': (state, action) =>
      produce(state, (draft) => {
        const { index, values } = action.payload;

        // 1. 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems);
        Object.entries(values).forEach(([name, value]) => {
          nestedAssign(missionItems[index].data, name.split('.'), value);
        });
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        // 5. 촬영 정의
        draft.shoots = getShoots(missionItems);

        draft.needSave = true;
      }),
    'EDITOR/APPEND_GRIPPER': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doGripper',
          data: {
            gripper: 1,
            action: 0, // 0: Release, 1: Grab
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_SET_RELAY': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doSetRelay',
          data: {
            relay: 0,
            setting: 1,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_CYCLE_RELAY': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doRepeatRelay',
          data: {
            relay: 0,
            count: 1,
            time: 10,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_SET_SERVO': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doSetServo',
          data: {
            servo: 1,
            pwm: 1500,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_CYCLE_SERVO': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doRepeatServo',
          data: {
            servo: 1,
            pwm: 1000,
            count: 1,
            time: 10,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_DELAY_UNTIL': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'navDelay',
          data: {
            hold: 30,
            hour: 0,
            minute: 0,
            second: 0,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_JUMP_TO_ITEM': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doJump',
          data: {
            item: 1,
            repeat: 10,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_CHANGE_ALTITUDE': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'navContinueAndChangeAlt',
          data: {
            altitude: 100,
            mode: 1,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_CHANGE_SPEED': (state, action) =>
      produce(state, (draft) => {
        const { speed } = action.payload;

        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doChangeSpeed',
          data: {
            type: 0,
            speed,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/EDIT_CHANGE_SPEED': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        // 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems);
        // 값이 undefined 인 경우 필드 제거
        if (value === undefined) {
          delete missionItems[index].data[name];
        } else {
          missionItems[index].data[name] = value;
        }
        draft.missionItems = missionItems;

        draft.needSave = true;
      }),
    'EDITOR/APPEND_WAIT_FOR_DISTANCE': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'condDistance',
          data: {
            distance: 10,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_WAIT_FOR_YAW': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'condYaw',
          data: {
            direction: 0,
            offset: 0,
            heading: 0,
            rate: 5,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_TRIGGER_PARACHUTE': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doParachute',
          data: {
            action: 1,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_GUIDED_ENABLE': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'navGuidedEnable',
          data: {
            enable: 1,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_GUIDED_LIMITS': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doGuidedLimits',
          data: {
            timeout: 0,
            minAlt: 25,
            maxAlt: 100,
            horizMoveLimit: 25,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/APPEND_AUTOTUNE_ENABLE': (state, action) =>
      produce(state, (draft) => {
        // 미션 아이템 정의
        const newMissionItem = {
          id: uuidv4(),
          type: 'doAutotuneEnable',
          data: {
            enable: 1,
          },
        };
        draft.missionItems = [...state.missionItems, newMissionItem];

        draft.needSave = true;
      }),
    'EDITOR/RESET_MISSION_ITEMS': (state, action) =>
      produce(state, (draft) => {
        draft.mode = 'point';
        draft.missionItems = [];
        draft.markers = [];
        draft.path = [];
        draft.labels = [];
        draft.shoots = [];
        draft.needSave = true;
      }),
    'EDITOR/REMOVE_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index } = action.payload;

        // 1. 미션 아이템 정의
        const missionItems = [...state.missionItems];
        missionItems.splice(index, 1);
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        // 5. 촬영 정의
        draft.shoots = getShoots(missionItems);

        draft.needSave = true;
      }),
    'EDITOR/SHIFT_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index, offset } = action.payload;

        // 1. 미션 아이템 정의
        const missionItems = [...state.missionItems];
        [missionItems[index], missionItems[index + offset]] = [missionItems[index + offset], missionItems[index]];
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/UPDATE_MISSION_ITEM': (state, action) =>
      produce(state, (draft) => {
        const { index, name, value } = action.payload;

        // 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems);
        missionItems[index].data[name] = value;
        draft.missionItems = missionItems;

        draft.needSave = true;
      }),
    'EDITOR/CHANGE_MISSION_ITEM_TYPE': (state, action) =>
      produce(state, (draft) => {
        const { index, type } = action.payload;

        // 1. 미션 아이템 정의
        const missionItems = deepCopy(state.missionItems);
        missionItems[index].type = type;

        // 2. 기본값 정의
        switch (type) {
          case 'navLoiterTime':
            missionItems[index].data.loiterTime = 30;
            break;

          case 'navLoiterTurns':
            missionItems[index].data.turns = 1;
            break;

          default:
            break;
        }
        draft.missionItems = missionItems;

        // 3. 마커 정의
        draft.markers = getMarkers(missionItems);

        draft.needSave = true;
      }),
    'EDITOR/APPEND_RETURN_TO_LAUNCH': (state, action) =>
      produce(state, (draft) => {
        // 1. 미션 아이템 정의
        const newMissionItem = { id: uuidv4(), type: 'navReturnToLaunch' };
        const missionItems = [...state.missionItems, newMissionItem];
        draft.missionItems = missionItems;

        // 2. 마커 정의
        draft.markers = getMarkers(missionItems);

        // 3. 경로 정의
        const path = getPath(missionItems);
        draft.path = path;

        // 4. 라벨 정의
        draft.labels = getPathLabels(path);

        draft.needSave = true;
      }),
    'EDITOR/ADD_GUIDE_MISSION': (state, action) =>
      produce(state, (draft) => {
        const { id, name, json } = action.payload;

        const newGuideMission = {
          id,
          name,
          path: getPath(json.missionItems),
        };
        draft.guideMissions = [...state.guideMissions, newGuideMission];
      }),
    'EDITOR/REMOVE_GUIDE_MISSION': (state, action) =>
      produce(state, (draft) => {
        const { index } = action.payload;

        const guideMissions = [...state.guideMissions];
        guideMissions.splice(index, 1);
        draft.guideMissions = guideMissions;
      }),

    //! When defining a new mission item,
    //! make sure to review the 'getMarkers' function
    //! if its options might generate two or more commands.
  },
  initialState
);

// 마커
const getMarkers = (missionItems) => {
  const draftMarkers = {};
  let labelNumber = 1;

  missionItems.forEach((missionItem) => {
    switch (missionItem.type) {
      case 'navLand':
        draftMarkers[missionItem.id] = {
          label: labelNumber,
          position: missionItem.data.position,
          draggable: true,
        };

        // Parachute 항목 체크한 경우
        if (missionItem.data.parachute) {
          labelNumber += 3; // Waypoint, Parachute Enable, Release 반영
        }
        break;

      case 'navLoiterTime':
      case 'navLoiterToAlt':
      case 'navLoiterTurns':
      case 'navTakeoff':
        draftMarkers[missionItem.id] = {
          label: labelNumber,
          position: missionItem.data.position,
          draggable: true,
        };
        break;

      case 'navWaypoint':
        draftMarkers[missionItem.id] = {
          label: labelNumber,
          position: missionItem.data.position,
          draggable: true,
        };

        // Speed 항목 체크한 경우
        if (missionItem.data.speed) {
          labelNumber++; // Change Speed 반영
        }
        break;

      case 'navReturnToLaunch':
        draftMarkers[missionItem.id] = {
          label: labelNumber,
          position: missionItems[0].data.position,
          draggable: false,
        };
        break;

      case 'cusSurvey':
        {
          const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
          const startLabel = labelNumber;
          const endLabel = labelNumber + surveyMissionItem.data.positions.length - 1;

          // 서베이 시작점
          draftMarkers[`${surveyMissionItem.id}/start`] = {
            label: startLabel,
            position: surveyMissionItem.data.positions.at(0),
            draggable: false,
          };

          // 서베이 종료점
          draftMarkers[`${surveyMissionItem.id}/end`] = {
            label: endLabel,
            position: surveyMissionItem.data.positions.at(-1),
            draggable: false,
          };

          labelNumber = endLabel;
        }
        break;

      default:
        break;
    }

    labelNumber++;
  });

  return draftMarkers;
};

// 경로
const getPath = (missionItems) => {
  const draftPath = [];
  missionItems.forEach((missionItem) => {
    switch (missionItem.type) {
      case 'navLand':
      case 'navLoiterTime':
      case 'navLoiterToAlt':
      case 'navLoiterTurns':
      case 'navTakeoff':
      case 'navWaypoint':
        draftPath.push({ id: missionItem.id, position: missionItem.data.position });
        break;

      case 'navReturnToLaunch':
        draftPath.push({ id: missionItem.id, position: missionItems[0].data.position });
        break;

      case 'cusSurvey':
        {
          const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
          surveyMissionItem.data.positions.forEach((position, index) => {
            let id = missionItem.id;
            // 서베이 시작점
            if (index === 0) {
              id = `${id}/start`;
            }
            // 서베이 종료점
            else if (index === surveyMissionItem.data.positions.length - 1) {
              id = `${id}/end`;
            }
            // 서베이 중간점
            else {
              id = `${id}/${index}`;
            }

            draftPath.push({ id, position });
          });
        }
        break;

      default:
        break;
    }
  });
  return draftPath;
};

// 라벨
const getPathLabels = (path) => {
  const filteredPath = path.filter(({ id }) => {
    return (
      !id.includes('/') || // 서베이 아닌 경로점
      id.endsWith('/start') || // 서베이 시작점
      id.endsWith('/end') // 서베이 종료점
    );
  });

  const draftLabels = [];
  filteredPath.slice(1).forEach(({ id }, index) => {
    // 서베이 종료점 아닌 경우 (서베이 구간 라벨 미표시)
    if (!id.endsWith('/end')) {
      draftLabels.push({
        id,
        from: filteredPath[index].position,
        to: filteredPath[index + 1].position,
      });
    }
  });

  return draftLabels;
};

// 촬영
const getShoots = (missionItems) => {
  const draftShoots = [];
  missionItems
    .filter((missionItem) => missionItem.data?.camera)
    .forEach((missionItem) => {
      const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
      const { altitude, rotate, positions, camera } = surveyMissionItem.data;
      const shootPaths = getShootPaths(positions);

      shootPaths.forEach((shootPath, pathIndex) => {
        const shootPoints = getShootPoints(shootPath, camera.interval);

        shootPoints.forEach((shootPoint, pointIndex) => {
          let id;
          // 촬영 시작점
          if (pointIndex === 0) {
            id = `${missionItem.id}/${pathIndex}/camera/start`;
          }
          // 촬영 중간점
          else if (pointIndex < shootPoints.length - 1) {
            id = `${missionItem.id}/${pathIndex}/${pointIndex}`;
          }
          // 촬영 종료점
          else {
            id = `${missionItem.id}/${pathIndex}/camera/end`;
          }

          draftShoots.push({
            id,
            position: CoordUtils.objectFromArray(shootPoint),
            boundary: getBoundary(shootPoint, rotate - 90, altitude, camera.options.aov),
          });
        });
      });
    });
  return draftShoots;
};

const getShootPaths = (positions) => {
  const triggerPositions = positions
    .filter((position) => Object.hasOwn(position, 'shoot'))
    .map((position) => CoordUtils.arrayFromObject(position));

  const paths = [];
  triggerPositions.forEach((triggerPosition, index) => {
    if (index % 2) {
      paths.push([triggerPositions[index - 1], triggerPosition]);
    }
  });
  return paths;
};

const getShootPoints = (path, interval) => {
  // Interval 의한 경로 분할
  const subpaths = lineChunk(lineString(path), interval / 1000).features;
  // 분할 경로 각 시작점 정의
  const shootPoints = subpaths.map((subpath) => subpath.geometry.coordinates[0]);
  // 경로 종료점 추가
  shootPoints.push(path[1]);

  return shootPoints;
};

const getBoundary = (coordinates, heading, altitude, aov) => {
  // 촬영 지점으로부터 전방, 측면 거리
  const forwardDistance = getDistanceToSide(aov.vertical, altitude);
  const sideDistance = getDistanceToSide(aov.horizontal, altitude);

  // 상하 좌표
  const [top, bottom] = [0, 180].map((degree) => getMovedCoordinate(coordinates, forwardDistance / 1000, degree));
  // 좌우 좌표
  const [left, right] = [-90, 90].map((degree) => getMovedCoordinate(coordinates, sideDistance / 1000, degree));

  // 촬영 영역 생성
  const points = featureCollection([point(top), point(right), point(bottom), point(left)]);
  const polygon = envelope(points);

  // 촬영 영역 회전
  const rotated = transformRotate(polygon, heading);
  const coords = rotated.geometry.coordinates[0];

  return coords.map((coord) => CoordUtils.objectFromArray(coord));
};

const getDistanceToSide = (degree, altitude) => {
  return Math.tan(degreesToRadians(degree / 2)) * altitude;
};

const getMovedCoordinate = (origin, distance, direction) => {
  const movedPoint = destination(origin, distance, direction);
  return movedPoint.geometry.coordinates;
};
