import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    timeout: useRef(),
    minAlt: useRef(),
    maxAlt: useRef(),
    horizMoveLimit: useRef(),
  }).current;

  useEffect(() => {
    domRefs.timeout.current.value = missionItem.data.timeout;
    domRefs.minAlt.current.value = missionItem.data.minAlt.toFixed(1);
    domRefs.maxAlt.current.value = missionItem.data.maxAlt.toFixed(1);
    domRefs.horizMoveLimit.current.value = missionItem.data.horizMoveLimit.toFixed(1);
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    let value = Number(e.target.value);

    // Timeout, Horizontal Move Limit 변경된 경우
    if (name === 'timeout' || name === 'horizMoveLimit') {
      value = Math.max(0, value);
    }

    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Timeout" unit="secs">
        <input
          ref={domRefs.timeout}
          name="timeout"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Min Relative Altitude" unit="m">
        <input
          ref={domRefs.minAlt}
          name="minAlt"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Max Relative Altitude" unit="m">
        <input
          ref={domRefs.maxAlt}
          name="maxAlt"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Horizontal Move Limit" unit="m">
        <input
          ref={domRefs.horizMoveLimit}
          name="horizMoveLimit"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
