import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const mode = useMemo(() => {
    switch (missionItem.data.mode) {
      case 0:
        return 'Neutral';
      case 1:
        return 'Climb';
      case 2:
        return 'Descend';
      default:
        break;
    }
  }, [missionItem.data.mode]);

  return (
    <div className={cx('container')}>
      <label>
        Relative Altitude
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.altitude.toFixed(1)} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
      <label>
        Mode
        <div className={cx('fieldWrapper')}>
          <input value={mode} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
    </div>
  );
};

export default Item;
