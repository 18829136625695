let handler;

const setHandler = (_handler) => {
  handler = _handler;
};

const success = (text) => {
  handler('success', text);
};

const error = (text) => {
  handler('error', text);
};

export { setHandler };

// eslint-disable-next-line import/no-anonymous-default-export
export default { success, error };
