import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState, useMemo } from 'react';

import styles from './Body.module.scss';

import { Attitude, Cell, Row } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';
import { getDistance } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const STATUS = {
  0: 'Uninitialized',
  1: 'Booting Up',
  2: 'Calibrating',
  3: 'On Stanby',
  4: 'Active',
  5: 'Critical',
  6: 'Emergency',
  7: 'Power Off',
  8: 'Termination',
};

const Body = ({ robotId }) => {
  const [armState, setArmState] = useState();
  const [status, setStatus] = useState();
  const [manualControl, setManualControl] = useState(false);
  const [globalPosition, setGlobalPosition] = useState();
  const [vfrHud, setVfrHud] = useState();
  const [homePosition, setHomePosition] = useState();

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
        setArmState(data.isArm ? 'ARM' : 'DISARM');
        setStatus(STATUS[data.status]);
      })
    );
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/paramValue`, (data) => {
        if (data.paramIndex === 154) {
          setManualControl(data.paramValue === 1);
        }
      })
    );
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/globalPosition`, setGlobalPosition));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/vfrHud`, setVfrHud));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/homePosition`, setHomePosition));

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  const distance = useMemo(() => {
    if (!globalPosition) return 0;
    if (!homePosition) return 0;

    return getDistance(globalPosition, homePosition);
  }, [globalPosition, homePosition]);

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <Row label="Arm State" value={armState} valueStyle={armState === 'DISARM' && { color: 'red' }} />
        <Row label="System Status" value={status} />
        <Row label="Distance to Home" value={commaNumber(distance.toFixed(2))} unit="m" />
      </div>
      <div className={cx('boxes')}>
        <Cell
          data={[
            {
              title: 'Speed',
              value: vfrHud ? commaNumber(vfrHud.groundspeed.toFixed(2)) : 0,
              unit: 'm/s',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Manual Control',
              value: manualControl ? 'ON' : 'OFF',
            },
          ]}
        />
      </div>
      <Attitude data={robotId} />
    </div>
  );
};

export default Body;
