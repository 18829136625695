import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useContext, useState, useMemo, useRef } from 'react';

import styles from './index.module.scss';
import ProcessingButton from '../ProcessingButton';

import Checkbox from '@/components/ui/Checkbox';
import EmptyList from '@/components/ui/EmptyList';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';
import { NotifierService as notifier } from '@/libs/Notifier';
import sleep from '@/utils/Sleep';

const cx = classNames.bind(styles);

const FORM_TYPE = {
  READ_ONLY: 1,
  FIELD: 2,
  SELECT: 3,
  CHECKBOX: 4,
};

const Parameters = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [paramIds, setParamIds] = useState();
  const [keyword, setKeyword] = useState('');
  const [paramInfo, setParamInfo] = useState();
  const paramDocs = useRef();
  const selectedParamId = useRef();

  // 노출 파라미터 선별
  const filteredParamIds = useMemo(() => {
    if (!paramIds) return [];
    // 검색 키워드 미입력 시
    if (keyword.trim().length === 0) return paramIds;

    return paramIds.filter((paramId) => {
      const upperCase = keyword.trim().toUpperCase();
      return paramId.includes(upperCase);
    });
  }, [paramIds, keyword]);

  useMountEffect(() => {
    loadParamDoc();
    publishCommand(robot, 'param/list', [[]]);

    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/paramValue`, (data) => {
      if (selectedParamId.current) {
        loadSingleParam(data);
      } else {
        loadAllParamIds(data);
      }
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  const loadParamDoc = () => {
    // TODO: Firmware, Frame, Version 고려한 파일 로드
    fetch('https://raw.githubusercontent.com/ArduPilot/ParameterRepository/refs/heads/main/Copter-4.3/apm.pdef.json')
      .then((response) => response.json())
      .then((json) => {
        paramDocs.current = Object.values(json).reduce((acc, params) => {
          return { ...acc, ...params };
        }, {});
      });
  };

  const loadSingleParam = (data) => {
    // 갱신 파라미터(65535)가 선택된 파라미터가 아닌 경우
    // e.g. STAT_RUNTIME 파라미터 수시 발생
    if (data[65535].paramId !== selectedParamId.current) return;

    const paramDoc = { ...paramDocs.current[selectedParamId.current] };
    Object.entries(paramDoc).forEach(([key, value]) => {
      const newKey = key[0].toLowerCase() + key.slice(1);
      paramDoc[newKey] = value;
      delete paramDoc[key];
    });

    // 양식 유형 정의
    let formType;
    if (Object.hasOwn(paramDoc, 'readOnly')) {
      formType = FORM_TYPE.READ_ONLY;
    } else if (Object.hasOwn(paramDoc, 'values')) {
      formType = FORM_TYPE.SELECT;
    } else if (Object.hasOwn(paramDoc, 'range')) {
      formType = FORM_TYPE.FIELD;
    } else if (Object.hasOwn(paramDoc, 'bitmask')) {
      formType = FORM_TYPE.CHECKBOX;
    }

    setParamInfo({
      formType,
      ...paramDoc,
      ...data[65535],
    });
  };

  const loadAllParamIds = (data) => {
    // 파라미터 1개 선별
    let randomParam;
    for (const key in data) {
      randomParam = data[key];
      break;
    }

    // 파라미터 선별 실패 시
    if (!randomParam) return;
    // 아직 총 파라미터 갯수 미만일 시
    if (Object.keys(data).length < randomParam.paramCount - 1) return;
    // 이미 파라미터 정의된 상태 시
    if (paramIds?.length > 0) return;

    const nextParamIds = Object.values(data)
      // 갱신 파라미터(65535) 제외
      .filter(({ paramIndex }) => paramIndex !== 65535)
      .map(({ paramId }) => paramId);

    setParamIds(nextParamIds);
  };

  const changeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const doSelect = (e) => {
    const paramId = e.target.dataset.id;

    selectedParamId.current = paramId;
    publishCommand(robot, 'param/read', [[paramId]]);
  };

  const changeValue = (e) => {
    setParamInfo({ ...paramInfo, paramValue: Number(e.target.value) });
  };

  const toggleCheck = (key) => {
    const bit = 1 << Number(key);
    const isBitOn = Boolean(paramInfo.paramValue & bit);

    let paramValue = paramInfo.paramValue;
    if (isBitOn) {
      paramValue &= ~bit;
    } else {
      paramValue |= bit;
    }

    setParamInfo({ ...paramInfo, paramValue });
  };

  const validAll = () => {
    if (paramInfo.formType === FORM_TYPE.FIELD) {
      const { paramValue, range } = paramInfo;

      if (paramValue < Number(range.low) || Number(range.high) < paramValue) {
        notifier.error('Please enter a value within the range.');
        return false;
      }
    }
    return true;
  };

  const doSave = () => {
    if (!validAll()) return;

    return new Promise((resolve) => {
      publishCommand(robot, 'param/set', [[paramInfo.paramId, paramInfo.paramValue, paramInfo.paramType]]);
      sleep(1000).then(() => resolve(true));
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('left')}>
        <input type="text" placeholder="ID" onChange={changeKeyword} />
        {filteredParamIds.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList />
          </div>
        )}
        {filteredParamIds.length > 0 && (
          <ul>
            {filteredParamIds.map((paramId) => (
              <li
                key={paramId}
                data-id={paramId}
                className={cx({ selected: paramId === selectedParamId.current })}
                onClick={doSelect}>
                {paramId}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={cx('console')}>
        {!paramInfo && (
          <div className={cx('empty')}>
            <EmptyList message="No item selected" />
          </div>
        )}
        {paramInfo && (
          <>
            <div className={cx('id')}>{paramInfo.paramId}</div>
            <div className={cx('info')}>
              <div className={cx('name')}>{paramInfo.displayName}</div>
              <div className={cx('desc')}>{paramInfo.description}</div>
            </div>
            <div className={cx('form')}>
              {paramInfo.formType === FORM_TYPE.READ_ONLY && <div className={cx('title')}>Current Value :</div>}
              {paramInfo.formType !== FORM_TYPE.READ_ONLY && <div className={cx('title')}>Change Value :</div>}
              {paramInfo.formType === FORM_TYPE.FIELD && (
                <>
                  <div className={cx('field')}>
                    <input type="number" value={paramInfo.paramValue} onChange={changeValue} />
                    <div className={cx('unit')}>{paramInfo.units}</div>
                  </div>
                  <ul className={cx('guide')}>
                    <li>∙ Min : {commaNumber(paramInfo.range.low)}</li>
                    <li>∙ Max : {commaNumber(paramInfo.range.high)}</li>
                    {Object.hasOwn(paramInfo, 'increment') && <li>∙ Increment : {commaNumber(paramInfo.increment)}</li>}
                  </ul>
                </>
              )}
              {paramInfo.formType === FORM_TYPE.SELECT && (
                <select value={paramInfo.paramValue} onChange={changeValue}>
                  {Object.entries(paramInfo.values).map(([key, value], index) => (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              )}
              {paramInfo.formType === FORM_TYPE.CHECKBOX && (
                <>
                  <div className={cx('field')}>
                    <input type="number" value={paramInfo.paramValue} readOnly />
                  </div>
                  <div className={cx('options')}>
                    {Object.entries(paramInfo.bitmask).map(([key, value]) => {
                      const isChecked = Boolean(paramInfo.paramValue & (1 << Number(key)));

                      return (
                        <div key={key} className={cx('option')}>
                          <Checkbox checked={isChecked} onClick={() => toggleCheck(key)} />
                          {value}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {paramInfo.formType !== FORM_TYPE.READ_ONLY && (
                <ProcessingButton text="Save" doProcess={doSave} className={cx('button')} />
              )}
              <div className={cx('warn')}>
                <span className={cx('accent')}>Warning</span>
                <br />
                Altering settings while the vehicle is in flight may result in instability or even loss of the vehicle.
                Ensure you fully understand the changes you’re making and carefully verify your inputs before saving!
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Parameters;
