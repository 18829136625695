import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import ChartJs from 'chart.js/auto';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState, useRef, useEffect } from 'react';

import styles from './Chart.module.scss';

import { getDistance, getDistanceAlongPath, getElevationAlongPath, getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Chart = ({ data: missionItems }) => {
  const [waypoints, setWaypoints] = useState();
  const chartRef = useRef();
  const chart = useRef();

  useEffect(() => {
    const nextWaypoints = [];
    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterTime':
        case 'navLoiterToAlt':
        case 'navLoiterTurns':
        case 'navTakeoff':
        case 'navWaypoint':
          nextWaypoints.push({
            ...missionItem,
            data: {
              ...missionItem.data,
              elevation: null, // To be assigned later
            },
          });
          break;

        case 'navReturnToLaunch':
          nextWaypoints.push({ ...missionItem, data: missionItems[0].data });
          break;

        default:
          break;
      }
    });

    // 최초 경로점 고도 지정
    getElevation(nextWaypoints[0].data.position).then((elevation) => {
      nextWaypoints[0].data.elevation = elevation;
      setWaypoints(nextWaypoints);
    });
  }, [missionItems]);

  useUpdateEffect(() => {
    drawChart();
  }, [waypoints]);

  const drawChart = async () => {
    let elevations;
    if (waypoints.length === 1) {
      elevations = [await getElevation(waypoints[0].data.position)];
    } else {
      elevations = await getElevationAlongPath(waypoints.map(({ data }) => data.position));
    }
    const chartData = getChartData(elevations);

    const mslAltitudes = waypoints.map(({ data }) => waypoints[0].data.elevation + data.altitude);
    const minValue = Math.ceil(Math.min(...elevations, ...mslAltitudes));
    const maxValue = Math.ceil(Math.max(...elevations, ...mslAltitudes));
    const margin = (maxValue - minValue) * 0.1; // 10% of the difference
    const rangeMin = minValue - margin;
    const rangeMax = maxValue + margin;

    // 차트 존재 시
    if (chart.current) {
      chart.current.data = chartData;
      chart.current.options.scales.yAxis.min = rangeMin;
      chart.current.options.scales.yAxis.max = rangeMax;
      chart.current.update();
    }
    // 차트 미존재 시
    else if (chartRef.current) {
      const context = chartRef.current.getContext('2d');
      const options = {
        type: 'line',
        data: chartData,
        options: {
          layout: {
            padding: {
              top: 4,
              right: 12,
              bottom: 12,
              left: 8,
            },
          },
          maintainAspectRatio: false,
          scales: {
            xAxis: { display: false },
            yAxis: {
              min: rangeMin,
              max: rangeMax,
              grid: { color: 'rgba(255, 255, 255, 0.1)' },
              ticks: {
                font: { size: 10 },
                callback: (value) => `${commaNumber(parseInt(value))}m`,
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: () => null, // Tooltip 제목 제거
              },
            },
          },
        },
      };

      chart.current = new ChartJs(context, options);
    }
  };

  const getChartData = (elevations) => {
    // 총 거리
    const wholeDistance = getDistanceAlongPath(waypoints.map(({ data }) => data.position));
    // 누적 거리
    let accumulatedDistance = 0;

    return {
      labels: elevations.map((_, index) => index + 1),
      datasets: [
        {
          label: 'MSL Altitude',
          data: waypoints.map((waypoint, index) => {
            let x = 0;
            if (index > 0) {
              const from = waypoints[index - 1].data.position;
              const to = waypoint.data.position;
              accumulatedDistance += getDistance(from, to);

              if (wholeDistance > 0) {
                x = Math.round((accumulatedDistance / wholeDistance) * elevations.length);
              }
            }

            let y = waypoints[0].data.elevation;
            // Land 경우
            if (waypoint.type === 'navLand') {
              // 직전 경로점 고도 동일
              y += waypoints[index - 1].data.altitude;
            } else {
              y += waypoint.data.altitude;
            }

            return { x, y };
          }),
          fill: true,
          borderColor: '#41a3ff',
          borderWidth: 1,
          pointBorderWidth: 0,
          backgroundColor: '#41a3ff22',
          datalabels: { display: false },
        },
        {
          label: 'Elevation',
          data: elevations.map((elevation, index) => ({
            x: index + 1,
            y: elevation,
          })),
          fill: true,
          borderColor: 'gold',
          borderWidth: 1,
          pointBorderWidth: 0,
          backgroundColor: '#ffd70022',
          datalabels: { display: false },
        },
      ],
    };
  };

  return (
    <div className={cx('container')}>
      <canvas ref={chartRef} className={cx('canvas')}></canvas>
    </div>
  );
};

export default Chart;
