import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';

import styles from './Cell.module.scss';

const cx = classNames.bind(styles);

const Cell = ({ data: items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const hasMulti = useMemo(() => {
    return items.length > 1;
  }, [items]);

  const toggle = (e) => {
    if (!hasMulti) return;

    e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % items.length);
  };

  return (
    <div className={cx(['container', { clickable: hasMulti }])} onClick={toggle}>
      <div className={cx('label')}>{items[currentIndex].title}</div>
      <div className={cx('value')}>
        {items[currentIndex].value}
        <span className={cx('unit')}>{items[currentIndex].unit}</span>
      </div>
      {hasMulti && (
        <div className={cx('pagination')}>
          {new Array(items.length).fill().map((_, index) => {
            return <div key={index} className={cx(['dot', { active: index === currentIndex }])} />;
          })}
        </div>
      )}
    </div>
  );
};

export default Cell;
