import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './GeoMagnetic.module.scss';

import Bar from '@/components/ui/Bar';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const GeoMagnetic = () => {
  const [values, setValues] = useState({
    kp: null,
    kk: null,
  });

  useMountEffect(() => {
    API.get('/proxy/geomagnetic').then(({ success, data }) => {
      if (success) {
        setValues({ kp: data.currentP, kk: data.currentK });
      }
    });
  });

  return (
    <div className={cx('container')}>
      <div className={cx('term')}>
        <div className={cx('label')}>Geo Magnetic</div>
        <div className={cx('value')}>
          Kp {values.kp ?? '-'} / Kk {values.kk ?? '-'}
        </div>
      </div>
      <Bar height={28} />
    </div>
  );
};

export default GeoMagnetic;
