import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState, useMemo } from 'react';
import { MdOutlineFlipCameraAndroid, MdOutlineStorm } from 'react-icons/md';

import styles from './Body.module.scss';

import Bar from '@/components/ui/Bar';
import { Attitude, Cell, Row } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const STATUS = {
  0: 'Uninitialized',
  1: 'Booting Up',
  2: 'Calibrating',
  3: 'On Stanby',
  4: 'Active',
  5: 'Critical',
  6: 'Emergency',
  7: 'Power Off',
  8: 'Termination',
};

const Body = ({ robotId }) => {
  const [armState, setArmState] = useState();
  const [status, setStatus] = useState();
  const [globalPosition, setGlobalPosition] = useState();
  const [vfrHud, setVfrHud] = useState();
  const [usvData, setUsvData] = useState();

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
        setArmState(data.isArm ? 'ARM' : 'DISARM');
        setStatus(STATUS[data.status]);
      })
    );
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/globalPosition`, setGlobalPosition));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/vfrHud`, setVfrHud));
    subscribeTokens.push(EventEmitter.subscribe(`${robotId}/telemetry/usvData`, setUsvData));

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  const getAnimationDuration = (percent) => {
    if (percent === 0) {
      return 0;
    }
    return `${(100 - percent) / 50 + 0.2}s`;
  };

  const shecoData = useMemo(() => {
    // prettier-ignore
    const nextData = {
      recoveredFlow: 0,   // 회수량
      battery1Remain: 0,  // 배터리 #1 잔량
      battery1Voltage: 0, // 배터리 #1 전압
      battery1Current: 0, // 배터리 #1 전류
      battery2Remain: 0,  // 배터리 #2 잔량
      battery2Voltage: 0, // 배터리 #2 전압
      battery2Current: 0, // 배터리 #2 전류
      propulsion1: 0,     // 추진체 #1 출력
      propulsion2: 0,     // 추진체 #2 출력
      recovery1: 0,       // 회수부 #1 출력
      recovery2: 0,       // 회수부 #2 출력
      recovery3: 0,       // 회수부 #3 출력
      recovery4: 0,       // 회수부 #4 출력
    };

    if (usvData) {
      nextData.recoveredFlow = Number(usvData.oilcollMotorEncCnt.toFixed(2));
      // 배터리
      nextData.battery1Remain = usvData.bms1Soc;
      nextData.battery1Voltage = usvData.bms1Vbat;
      nextData.battery1Current = usvData.bms1Curr;
      nextData.battery2Remain = usvData.bms2Soc;
      nextData.battery2Voltage = usvData.bms2Vbat;
      nextData.battery2Current = usvData.bms2Curr;
      // 추진체
      nextData.propulsion1 = usvData.thrUtilRate[0] ?? 0;
      nextData.propulsion2 = usvData.thrUtilRate[1] ?? 0;
      // 회수부
      nextData.recovery1 = usvData.oilcollMotorRotSpd[0] ?? 0;
      nextData.recovery2 = usvData.oilcollMotorRotSpd[1] ?? 0;
      nextData.recovery3 = usvData.oilcollMotorRotSpd[2] ?? 0;
      nextData.recovery4 = usvData.oilcollMotorRotSpd[3] ?? 0;
    }

    return nextData;
  }, [usvData]);

  return (
    <div className={cx('container')}>
      <div className={cx('rows')}>
        <Row label="Arm State" value={armState} valueStyle={armState === 'DISARM' && { color: 'red' }} />
        <Row label="System Status" value={status} />
        <Row
          label="Current Position"
          value={globalPosition ? `${globalPosition.lat.toFixed(6)}, ${globalPosition.lng.toFixed(6)}` : '-'}
        />
      </div>
      <div className={cx('boxes')}>
        <div className={cx(['box', 'battery'])}>
          <div className={cx('label')}>Battery Status</div>
          <div className={cx('items')}>
            <div className={cx('item')}>
              <div className={cx('battery')}>
                <div className={cx('bar')} style={{ width: `${shecoData.battery1Remain}%` }} />
                <div className={cx('value')}>
                  {shecoData.battery1Remain}
                  <span className={cx('unit')}>%</span>
                </div>
              </div>
              <div className={cx('info')}>
                <div className={cx('value')}>
                  {shecoData.battery1Voltage}
                  <span className={cx('unit')}>V</span>
                </div>
                <div className={cx('value')}>
                  {shecoData.battery1Current}
                  <span className={cx('unit')}>A</span>
                </div>
              </div>
            </div>
            <Bar height={20} />
            <div className={cx('item')}>
              <div className={cx('battery')}>
                <div className={cx('bar')} style={{ width: `${shecoData.battery2Remain}%` }} />
                <div className={cx('value')}>
                  {shecoData.battery2Remain}
                  <span className={cx('unit')}>%</span>
                </div>
              </div>
              <div className={cx('info')}>
                <div className={cx('value')}>
                  {shecoData.battery2Voltage}
                  <span className={cx('unit')}>V</span>
                </div>
                <div className={cx('value')}>
                  {shecoData.battery2Current}
                  <span className={cx('unit')}>A</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('boxes')}>
        <Cell
          data={[
            {
              title: 'Speed',
              value: vfrHud ? commaNumber(vfrHud.groundspeed.toFixed(2)) : 0,
              unit: 'm/s',
            },
          ]}
        />
        <Cell
          data={[
            {
              title: 'Recovered Flow',
              value: shecoData.recoveredFlow,
              unit: 'L/h',
            },
          ]}
        />
      </div>
      <div className={cx('boxes')}>
        <div className={cx(['box', 'motor'])}>
          <div className={cx('label')}>Propulsion</div>
          <div className={cx('items')}>
            <div className={cx('item')}>
              <MdOutlineStorm
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.propulsion1) }}
              />
              <div className={cx('value')}>
                {shecoData.propulsion1}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
            <div className={cx('item')}>
              <MdOutlineStorm
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.propulsion2) }}
              />
              <div className={cx('value')}>
                {shecoData.propulsion2}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(['box', 'motor'])} style={{ flex: 1.5 }}>
          <div className={cx('label')}>Recovery</div>
          <div className={cx('items')}>
            <div className={cx('item')}>
              <MdOutlineFlipCameraAndroid
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.recovery1) }}
              />
              <div className={cx('value')}>
                {shecoData.recovery1}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
            <div className={cx('item')}>
              <MdOutlineFlipCameraAndroid
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.recovery2) }}
              />
              <div className={cx('value')}>
                {shecoData.recovery2}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
            <div className={cx('item')}>
              <MdOutlineFlipCameraAndroid
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.recovery3) }}
              />
              <div className={cx('value')}>
                {shecoData.recovery3}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
            <div className={cx('item')}>
              <MdOutlineFlipCameraAndroid
                size={24}
                color="white"
                className={cx('rotate')}
                style={{ animationDuration: getAnimationDuration(shecoData.recovery4) }}
              />
              <div className={cx('value')}>
                {shecoData.recovery4}
                <span className={cx('unit')}>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Attitude data={robotId} />
    </div>
  );
};

export default Body;
