import classNames from 'classnames/bind';
import React from 'react';

import GeoMagnetic from './GeoMagnetic';
import styles from './index.module.scss';
import Weather from './Weather';

import Bar from '@/components/ui/Bar';
import Geo from '@/components/ui/Geo';

const cx = classNames.bind(styles);

const BottomBar = () => {
  return (
    <div className={cx('container')}>
      {process.env.REACT_APP_USE_WEATHER === 'true' && <Weather />}
      {process.env.REACT_APP_USE_GEO_MAGNETIC === 'true' && <GeoMagnetic />}
      <Bar height={28} />
      <Geo />
    </div>
  );
};

export default BottomBar;
