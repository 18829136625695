import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from './Checklist.module.scss';
import ModalSpinner from '../ui/ModalSpinner';

import SettingsModal from '@/components/modals/Settings';
import ModalWrapper from '@/components/ui/Modal';
import API from '@/helpers/API';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Modal = ({ robots }) => {
  const [selectedRobotId, setSelectedRobotId] = useState(robots[0].id);
  const [checklists, setChecklists] = useState({});

  useEffect(() => {
    if (Object.hasOwn(checklists, selectedRobotId)) return;

    API.get(`/robots/${selectedRobotId}/checklist`).then(({ success, data }) => {
      if (success) {
        const nextChecklist = data.map((item) => ({ ...item, isChecked: false }));
        setChecklists({
          ...checklists,
          [selectedRobotId]: nextChecklist,
        });
      }
    });
  }, [selectedRobotId]);

  const close = () => {
    modal.hide();
  };

  const selectRobot = (e) => {
    setSelectedRobotId(e.target.value);
  };

  const moveToEdit = () => {
    const robot = robots.find(({ id }) => id === selectedRobotId);
    modal.show(SettingsModal, { robot, tabIndex: 3 });
  };

  const check = (index) => {
    const nextChecklist = [...checklists[selectedRobotId]];
    nextChecklist[index].isChecked = !nextChecklist[index].isChecked;

    setChecklists({
      ...checklists,
      [selectedRobotId]: nextChecklist,
    });
  };

  const confirm = () => {
    const isAllChecked = robots.every((robot) => {
      // 체크리스트 정보가 없는 경우
      if (!checklists[robot.id]) return false;

      // 모든 항목이 체크된 경우
      return checklists[robot.id].every(({ isChecked }) => isChecked);
    });

    if (!isAllChecked) {
      notifier.error('Please check all items.');
      return;
    }

    robots.forEach((robot) => {
      const storeKey = `@preventChecklist/${robot.id}`;
      localStorage.setItem(storeKey, moment().format('YYYYMMDD'));
    });

    close();
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div>
          <select onChange={selectRobot}>
            {robots.map((robot, index) => (
              <option key={index} value={robot.id}>
                {robot.name}
              </option>
            ))}
          </select>
          <div className={cx('table')}>
            <div className={cx('header')}>
              <div className={cx(['column', 'no'])}>No</div>
              <div className={cx(['column', 'question'])}>Question</div>
              <div className={cx(['column', 'check'])}>Check</div>
            </div>
            <div className={cx('body')}>
              {checklists[selectedRobotId] === undefined && <ModalSpinner />}
              {checklists[selectedRobotId]?.length === 0 && (
                <div className={cx('empty')}>
                  <div className={cx('title')}>No Items Yet</div>
                  <div className={cx('message')}>
                    Set up checklist items
                    <br />
                    and review before operating.
                  </div>
                  <div className={cx('button')} onClick={moveToEdit}>
                    Move to Edit
                  </div>
                </div>
              )}
              {checklists[selectedRobotId]?.map((item, index) => (
                <div key={index} className={cx('row')}>
                  <div className={cx(['column', 'no'])}>{index + 1}</div>
                  <div className={cx(['column', 'question'])}>{item.question}</div>
                  <div className={cx(['column', 'check'])}>
                    {item.isChecked ? (
                      <RiCheckboxCircleFill size={20} color="#41a3ff" onClick={() => check(index)} />
                    ) : (
                      <RiCheckboxBlankCircleLine size={20} color="white" onClick={() => check(index)} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={confirm}>
          Confirm
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
