import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import moment from 'moment';
import React, { useRef, useMemo, useState } from 'react';
import {
  RiFile3Line,
  RiFolderOpenLine,
  RiFileDownloadLine,
  RiFileUploadLine,
  RiSave3Line,
  RiSettings5Line,
} from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import styles from './index.module.scss';
import MissionItem from './MissionItem';
import Specs from './Specs';

import actions from '@/actions';
import AddTakeoffModal from '@/components/modals/AddTakeoff';
import EditorBatchModal from '@/components/modals/EditorBatch';
import EditorConfigModal from '@/components/modals/EditorConfig';
import MissionListModal, { PURPOSE } from '@/components/modals/MissionList';
import Bar from '@/components/ui/Bar';
import EmptyList from '@/components/ui/EmptyList';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import { postMission, patchMission } from '@/helpers/Requester';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';
import { getDistanceAlongPath } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Panel = () => {
  const store = useStore();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const specs = useSelector((state) => state.editor.specs);
  const name = useSelector((state) => state.editor.name);
  const missionItems = useSelector((state) => state.editor.missionItems);
  const [showSpeedPanel, setShowSpeedPanel] = useState(false);
  const [averageSpeed, setAverageSpeed] = useState(10);
  const fileRef = useRef();
  const settingsRef = useRef();
  const speedPanelRef = useRef();
  const speedFieldRef = useRef();
  const bodyRef = useRef();
  const lastMissionItemLength = useRef(0);

  const totalDistance = useMemo(() => {
    const path = [];
    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterTime':
        case 'navLoiterToAlt':
        case 'navLoiterTurns':
        case 'navTakeoff':
        case 'navWaypoint':
          path.push(missionItem.data.position);
          break;

        case 'navReturnToLaunch':
          path.push(missionItems[0].data.position);
          break;

        case 'cusSurvey':
          {
            const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
            surveyMissionItem.data.positions.forEach((position) => path.push(position));
          }
          break;

        default:
          break;
      }
    });

    return getDistanceAlongPath(path);
  }, [missionItems]);

  const time = useMemo(() => {
    const value = totalDistance / averageSpeed;

    if (Number.isFinite(value) && value > 0) {
      return value * 1000;
    }
    return 0;
  }, [totalDistance, averageSpeed]);

  useUpdateEffect(() => {
    if (lastMissionItemLength.current < missionItems.length) {
      bodyRef.current.scrollTo({ top: bodyRef.current.scrollHeight });
    }
    lastMissionItemLength.current = missionItems.length;
  }, [missionItems.length]);

  useUpdateEffect(() => {
    if (showSpeedPanel) {
      speedFieldRef.current.focus();
      document.addEventListener('mousedown', unfocusPanel);
    } else {
      document.removeEventListener('mousedown', unfocusPanel);
    }

    return () => {
      document.removeEventListener('mousedown', unfocusPanel);
    };
  }, [showSpeedPanel]);

  const unfocusPanel = (e) => {
    if (settingsRef.current.contains(e.target)) return;
    if (speedPanelRef.current.contains(e.target)) return;

    setShowSpeedPanel(false);
  };

  const handleInput = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (file) => {
      const data = JSON.parse(file.target.result);
      dispatch(actions.editor.load(data));
      navigate('/mission-hub/new', { replace: true });
    };
    e.target.value = '';
  };

  const replaceToNew = () => {
    window.location.replace('/mission-hub/new');
  };

  const doLoad = () => {
    modal.show(MissionListModal, { purpose: PURPOSE.EDIT });
  };

  const doUpload = () => {
    fileRef.current.click();
  };

  const doDownload = () => {
    if (!validAll()) return;

    const link = document.createElement('a');
    link.download = `${new Date().toISOString()}.json`;
    link.href = URL.createObjectURL(new Blob([JSON.stringify({ name, json: missionItems })], { type: 'text/json' }));
    link.click();
    link.remove();
  };

  const validAll = () => {
    if (missionItems.length === 0) {
      notifier.error('No mission defined.');
      return false;
    }
    if (name.trim() === '') {
      notifier.error('Please enter the mission name.');
      return false;
    }
    if (!validWithJumpToItem()) {
      notifier.error("Please check the item number in the 'Jump To Item'.");
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validAll()) return;

    let request;
    if (params.id === 'new') {
      request = postMission(name, { specs, missionItems });
    } else {
      request = patchMission(params.id, name, { specs, missionItems });
    }

    request.then(({ success, data }) => {
      if (success) {
        dispatch(actions.editor.save());
        navigate(`/mission-hub/${data?.id ?? params.id}`, { replace: true });
        notifier.success('The mission have been saved.');
      }
      // 미션명 존재하는 경우
      else if (data.code === 2001) {
        notifier.error('The mission name already exists.');
      }
    });
  };

  const doSaveAs = () => {
    if (!validAll()) return;

    const json = {
      specs,
      missionItems: missionItems.map((missionItem) => ({
        ...missionItem,
        id: uuidv4(), // 각 미션 아이템 신규 ID 정의
      })),
    };

    postMission(name, json).then(({ success, data }) => {
      if (success) {
        dispatch(actions.editor.save());
        navigate(`/mission-hub/${data.id}`, { replace: true });
        notifier.success('The mission have been saved with new name.');
      }
      // 미션명 존재하는 경우
      else if (data.code === 2001) {
        notifier.error('The mission name already exists.');
      }
    });
  };

  const handleChangeName = (e) => {
    dispatch(actions.editor.changeName(e.target.value));
  };

  const toggleSpeedPanel = () => {
    setShowSpeedPanel(!showSpeedPanel);
  };

  const changeAverageSpeed = (e) => {
    setAverageSpeed(Number(e.target.value));
  };

  const showSetDefault = () => {
    modal.show(EditorConfigModal);
  };

  const showEditAll = () => {
    modal.show(EditorBatchModal);
  };

  const closeAll = () => {
    missionItems.forEach(({ id }) => {
      EventEmitter.publish(`missionItem/${id}/toggle`, false);
    });
  };

  const deleteAll = () => {
    modal.show(AddTakeoffModal);
    dispatch(actions.editor.resetMissionItems());
  };

  const addCommand = (e) => {
    if (missionItems.length === 0) {
      notifier.error('Please add a takeoff point first.');
      return;
    }

    switch (e.target.value) {
      case 'autotuneEnable':
        dispatch(actions.editor.appendAutotuneEnable());
        break;

      case 'changeAltitude':
        dispatch(actions.editor.appendChangeAltitude());
        break;

      case 'changeSpeed':
        {
          const { speed } = store.getState().editor.config;
          dispatch(actions.editor.appendChangeSpeed(speed));
        }
        break;

      case 'cycleRelay':
        dispatch(actions.editor.appendCycleRelay());
        break;

      case 'cycleServo':
        dispatch(actions.editor.appendCycleServo());
        break;

      case 'delayUntil':
        dispatch(actions.editor.appendDelayUntil());
        break;

      case 'gripper':
        dispatch(actions.editor.appendGripper());
        break;

      case 'guidedEnable':
        dispatch(actions.editor.appendGuidedEnable());
        break;

      case 'guidedLimits':
        dispatch(actions.editor.appendGuidedLimits());
        break;

      case 'jumpToItem':
        dispatch(actions.editor.appendJumpToItem());
        break;

      case 'returnToLaunch':
        dispatch(actions.editor.appendReturnToLaunch());
        break;

      case 'setRelay':
        dispatch(actions.editor.appendSetRelay());
        break;

      case 'setServo':
        dispatch(actions.editor.appendSetServo());
        break;

      case 'triggerParachute':
        dispatch(actions.editor.appendTriggerParachute());
        break;

      case 'waitForDistance':
        dispatch(actions.editor.appendWaitForDistance());
        break;

      case 'waitForYaw':
        dispatch(actions.editor.appendWaitForYaw());
        break;

      default:
        break;
    }
  };

  const validWithJumpToItem = () => {
    if (!missionItems.some(({ type }) => type === 'doJump')) return true;

    let jumpToItemNumber = 1;
    let missionItemCount = 0;

    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'doJump':
          jumpToItemNumber = Math.max(jumpToItemNumber, missionItem.data.item);
          break;

        case 'navLand':
          // Parachute 항목 체크한 경우
          if (missionItem.data.parachute) {
            missionItemCount += 3; // Waypoint, Parachute Enable, Release 반영
          }
          break;

        case 'navWaypoint':
          // Speed 항목 체크한 경우
          if (missionItem.data.speed) {
            missionItemCount++; // Change Speed 반영
          }
          break;

        case 'cusSurvey':
          {
            const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
            missionItemCount += surveyMissionItem.data.positions.length - 1;
          }
          break;

        default:
          break;
      }

      missionItemCount++;
    });

    return jumpToItemNumber <= missionItemCount;
  };

  return (
    <div className={cx('container')}>
      <input ref={fileRef} hidden type="file" accept=".json" onInput={handleInput} />
      <div className={cx('filebar')}>
        <div onClick={replaceToNew} className={cx('button')}>
          <RiFile3Line size={20} color="white" />
        </div>
        <Bar height={16} />
        <div onClick={doLoad} className={cx('button')}>
          <RiFolderOpenLine size={20} color="white" />
        </div>
        <Bar height={16} />
        <div onClick={doUpload} className={cx('button')}>
          <RiFileUploadLine size={20} color="white" />
        </div>
        <Bar height={16} />
        <div onClick={doDownload} className={cx('button')}>
          <RiFileDownloadLine size={20} color="white" />
        </div>
      </div>
      <div className={cx('namebar')}>
        <input
          type="text"
          value={name}
          maxLength={24}
          placeholder="Name"
          className={cx('field')}
          onChange={handleChangeName}
        />
        <button type="button" className={cx('button')} onClick={doSave}>
          <RiSave3Line size={16} color="white" />
        </button>
        <button type="button" className={cx('button')} onClick={doSaveAs}>
          <RiSave3Line size={16} color="white" />
          <div className={cx('as')}>As</div>
        </button>
      </div>
      <Specs />
      <div className={cx('summary')}>
        <div className={cx('total')}>Total {commaNumber(missionItems.length)}</div>
        <div className={cx('values')}>
          <div className={cx('item')}>
            {commaNumber((totalDistance / 1000).toFixed(1))}
            <span>km</span>
          </div>
          <Bar />
          <div className={cx('item')}>{moment.utc(time).format('HH:mm:ss')}</div>
          <div className={cx('speedSettings')}>
            <div ref={settingsRef} className={cx('settings')} onClick={toggleSpeedPanel}>
              <RiSettings5Line size={14} color="gray" />
            </div>
            <div ref={speedPanelRef} className={cx(['panel', { show: showSpeedPanel }])}>
              <div className={cx('arrow')} />
              <label>
                Average Speed
                <div className={cx('fieldWrapper')}>
                  <input
                    ref={speedFieldRef}
                    name="averageSpeed"
                    type="number"
                    defaultValue={averageSpeed}
                    onChange={changeAverageSpeed}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        toggleSpeedPanel();
                      }
                    }}
                  />
                  <span className={cx('unit')}>m/s</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('toolbar')}>
        <button type="button" className={cx('button')} onClick={showSetDefault}>
          <div className={cx('label')}>Set Defaults</div>
        </button>
        <button type="button" className={cx('button')} onClick={showEditAll}>
          <div className={cx('label')}>Edit All</div>
        </button>
        <button type="button" className={cx('button')} onClick={closeAll}>
          <div className={cx('label')}>Close All</div>
        </button>
        <button type="button" className={cx('button')} onClick={deleteAll}>
          <div className={cx('label')}>Delete All</div>
        </button>
      </div>
      <div ref={bodyRef} className={cx('body')}>
        {missionItems.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList message="No items" />
          </div>
        )}
        {missionItems.length > 0 && (
          <div className={cx('inner')}>
            {missionItems.map((missionItem, index) => (
              <MissionItem key={missionItem.id} index={index} data={missionItem} />
            ))}
          </div>
        )}
      </div>
      <div className={cx('bottom')}>
        <div className={cx('title')}>Add Command</div>
        {
          // prettier-ignore
          <select value="" onChange={addCommand}>
            <option value="">Select</option>
            {specs.firmware === 'ardupilot' && <option value="autotuneEnable">Autotune Enable</option>}
            {specs.firmware === 'ardupilot' && <option value="changeAltitude">Change Altitude</option>}
            <option value="changeSpeed">Change Speed</option>
            {specs.firmware === 'ardupilot' && <option value="cycleRelay">Cycle Relay</option>}
            {specs.firmware === 'ardupilot' && <option value="cycleServo">Cycle Servo</option>}
            <option value="delayUntil">Delay Until</option>
            {specs.firmware === 'ardupilot' && <option value="gripper">Gripper</option>}
            {specs.firmware === 'ardupilot' && <option value="guidedEnable">Guided Enable</option>}
            {specs.firmware === 'ardupilot' && <option value="guidedLimits">Guided Limits</option>}
            <option value="jumpToItem">Jump To Item</option>
            <option value="returnToLaunch">Return To Launch</option>
            {specs.firmware === 'ardupilot' && <option value="setRelay">Set Relay</option>}
            <option value="setServo">Set Servo</option>
            {specs.firmware === 'ardupilot' && <option value="triggerParachute">Trigger Parachute</option>}
            {specs.firmware === 'ardupilot' && <option value="waitForDistance">Wait For Distance</option>}
            {specs.firmware === 'ardupilot' && <option value="waitForYaw">Wait For Yaw</option>}
          </select>
        }
      </div>
    </div>
  );
};

export default Panel;
