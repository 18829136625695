import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Status } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robotId }) => {
  const [status, setStatue] = useState({
    voltage: null,
    current: null,
  });

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/lowstate`, (data) => {
      setStatue({
        voltage: data.power_v,
        current: data.power_a,
      });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <Status
      data={{
        current: status.current,
        voltage: status.voltage,
      }}
    />
  );
};

export default Component;
