import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    hold: useRef(),
    hour: useRef(),
    minute: useRef(),
    second: useRef(),
    time: useRef(),
  }).current;

  useEffect(() => {
    domRefs.hold.current.value = missionItem.data.hold;
    domRefs.hour.current.value = missionItem.data.hour;
    domRefs.minute.current.value = missionItem.data.minute;
    domRefs.second.current.value = missionItem.data.second;
  }, [missionItem.data]);

  useMountEffect(() => {
    const updateUTCTime = () => {
      domRefs.time.current.innerText = moment().utc().format('HH:mm:ss');
    };

    updateUTCTime();
    const intervalId = setInterval(updateUTCTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Hold" unit="secs">
        <input
          ref={domRefs.hold}
          name="hold"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Hour (UTC)">
        <input
          ref={domRefs.hour}
          name="hour"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Minute (UTC)">
        <input
          ref={domRefs.minute}
          name="minute"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Second (UTC)">
        <input
          ref={domRefs.second}
          name="second"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <div className={cx('message')}>
        The current time in UTC is <span ref={domRefs.time} />
      </div>
    </div>
  );
};

export default MissionItem;
