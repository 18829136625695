import classNames from 'classnames/bind';
import React, { useState, useEffect, useMemo } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import styles from './Tutorial.module.scss';
import { MENUS } from '../../define/tutorial';
import ModalWrapper from '../ui/Modal';

import Bar from '@/components/ui/Bar';
import Checkbox from '@/components/ui/Checkbox';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const [indexes, setIndexes] = useState({ sup: 0, sub: 0 });
  const [sceneIndex, setSceneIndex] = useState(0);
  const [isDontShow, setIsDontShow] = useState(false);

  const scenes = useMemo(() => {
    const sup = MENUS[indexes.sup];
    if (Object.hasOwn(sup, 'menus')) {
      return sup.menus[indexes.sub].scenes;
    }
    return sup.scenes;
  }, [indexes]);

  useEffect(() => {
    // 장면 전환
    const timeout = setTimeout(() => {
      setSceneIndex((prev) => (prev + 1) % scenes.length);
    }, scenes[sceneIndex].duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [scenes, sceneIndex]);

  // 항목 선택
  const selectSup = (e) => {
    setSceneIndex(0);
    setIndexes({
      sup: Number(e.currentTarget.dataset.sup),
      sub: 0,
    });
  };

  // 하위 항목 선택
  const selectSub = (e) => {
    setSceneIndex(0);
    setIndexes({
      sup: Number(e.currentTarget.dataset.sup),
      sub: Number(e.currentTarget.dataset.sub),
    });
  };

  const changeScene = (e) => {
    const direction = e.currentTarget.dataset.direction;

    setSceneIndex((prev) => {
      switch (direction) {
        case 'prev':
          return (prev - 1 + scenes.length) % scenes.length;
        case 'next':
          return (prev + 1) % scenes.length;
        default:
          return;
      }
    });
  };

  const toggleIsDontShow = () => {
    setIsDontShow(!isDontShow);
  };

  const close = () => {
    // 다음 노출 방지
    if (isDontShow) {
      localStorage.setItem('@preventTutorial', Date.now());
    }

    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('menus')}>
          {MENUS.map((sup, supIndex) => {
            const isSelectedSup = supIndex === indexes.sup;

            return (
              <div key={supIndex} className={cx('menuGroup')}>
                <div data-sup={supIndex} onClick={selectSup} className={cx(['menu', { target: isSelectedSup }])}>
                  {sup.title}
                </div>
                {sup.menus && (
                  <div className={cx('submenus')}>
                    {sup.menus.map((sub, subIndex) => {
                      const isSelectedSub = subIndex === indexes.sub;

                      return (
                        <div
                          key={subIndex}
                          data-sup={supIndex}
                          data-sub={subIndex}
                          onClick={selectSub}
                          className={cx(['submenu', { target: isSelectedSup && isSelectedSub }])}>
                          {sub.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={cx('content')}>
          {scenes.map((scene, index) => (
            <img
              key={index}
              src={scene.image}
              alt={MENUS[indexes.sup].title}
              className={cx(['image', { target: index <= sceneIndex }])}
            />
          ))}
          {scenes[sceneIndex].desc && <div className={cx('desc')}>{scenes[sceneIndex].desc}</div>}
          <div className={cx('pagination')}>
            <button type="button" data-direction="prev" onClick={changeScene}>
              <RiArrowLeftSLine size={20} color="white" />
            </button>
            <div className={cx('middle')}>
              <Bar />
              <div className={cx('pages')}>
                <span className={cx('current')}>{sceneIndex + 1}</span>&nbsp;&nbsp;/&nbsp;&nbsp;{scenes.length}
              </div>
              <Bar />
            </div>
            <button type="button" data-direction="next" onClick={changeScene}>
              <RiArrowRightSLine size={20} color="white" />
            </button>
          </div>
        </div>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Close
        </button>
        <div className={cx('option')}>
          <Checkbox checked={isDontShow} size={16} onClick={toggleIsDontShow} />
          Don't show again
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
