import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Notifications.module.scss';

import EmptyList from '@/components/ui/EmptyList';

const cx = classNames.bind(styles);

const HOVER_ROW = {
  index: -1,
  isOverflow: false,
};

const Notifications = ({ data: notifications, open }) => {
  const [hoverRow, setHoverRow] = useState(HOVER_ROW);

  const updateHoverRow = (e, index) => {
    const textElement = e.currentTarget.querySelector('[data-text]');
    const isOverflow = textElement.clientWidth < textElement.scrollWidth;
    setHoverRow({ index, isOverflow });
  };

  return (
    <div className={cx(['container', { open }])}>
      {(!notifications || notifications.length === 0) && (
        <div className={cx('empty')}>
          <EmptyList message="No items" />
        </div>
      )}
      {notifications?.map((notification, index) => (
        <div
          key={index}
          className={cx('row')}
          onMouseEnter={(e) => updateHoverRow(e, index)}
          onMouseLeave={() => setHoverRow(HOVER_ROW)}>
          <div className={cx('textWrapper')}>
            <div data-text className={cx(['text', { marquee: index === hoverRow.index && hoverRow.isOverflow }])}>
              {notification.text}
            </div>
          </div>
          <div className={cx('time')}>{notification.time}</div>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
