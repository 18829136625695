import classNames from 'classnames/bind';
import React from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      <label>
        Distance
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.distance.toFixed(1)} readOnly />
          <span className={cx('unit')}>m</span>
        </div>
      </label>
    </div>
  );
};

export default Item;
