import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './common.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const setting = useMemo(() => {
    switch (missionItem.data.setting) {
      case 0:
        return 'Off';
      case 1:
        return 'On';
      default:
        return;
    }
  }, [missionItem.data.setting]);

  return (
    <div className={cx('container')}>
      <label>
        Relay Instance Number
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.relay} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
      <label>
        Setting
        <div className={cx('fieldWrapper')}>
          <input value={setting} readOnly />
          <span className={cx('unit')} />
        </div>
      </label>
    </div>
  );
};

export default Item;
