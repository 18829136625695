import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { Draw } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke } from 'ol/style';
import React, { useRef, useState, useMemo } from 'react';
import { RiSketching } from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';
import LineDrawer from '../LineDrawer';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const style = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
});

const DrawCurvedLine = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.editor.mode);
  const store = useStore();
  const [points, setPoints] = useState();
  const interaction = useRef();
  const map = OlMap.getMap();

  const isActive = useMemo(() => {
    return mode === 'curvedLine';
  }, [mode]);

  useUpdateEffect(() => {
    if (!isActive) return;

    const source = new VectorSource({ wrapX: false });
    const layer = new VectorLayer({ source, style });
    map.addLayer(layer);

    interaction.current = new Draw({
      source,
      type: 'LineString',
      style,
      freehand: true, // 곡선 그리기 허용
    });
    interaction.current.on('drawend', drawLine);
    map.addInteraction(interaction.current);

    return () => {
      map.removeLayer(layer);
      map.removeInteraction(interaction.current);
      interaction.current = null;

      setPoints();
    };
  }, [isActive]);

  const drawLine = (e) => {
    map.removeInteraction(interaction.current);
    interaction.current = null;

    const coordinates = e.feature.getGeometry().getCoordinates();
    const nextPoints = coordinates
      // 중복 좌표 제거
      .filter((coordinate, index) => {
        const prev = JSON.stringify(coordinate);
        const next = JSON.stringify(coordinates[index + 1]);
        return prev !== next;
      })
      // 지리 좌표계 변환
      .map((coordinate) => toLonLat(coordinate));

    setPoints(nextPoints);
  };

  const toggle = () => {
    if (store.getState().editor.missionItems.length === 0) {
      notifier.error('Please add a takeoff point first.');
      return;
    }

    if (isActive) {
      dispatch(actions.editor.changeMode(null));
    } else {
      dispatch(actions.editor.changeMode('curvedLine'));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['button', { active: isActive }])} onClick={toggle}>
        <RiSketching size={16} />
        <div className={cx('label')}>Curved Line</div>
      </div>
      {isActive && points && <LineDrawer points={points} onCancelOrSubmit={toggle} />}
    </div>
  );
};

export default DrawCurvedLine;
