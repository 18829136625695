import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import styles from './index.module.scss';
import ProcessingButton from '../ProcessingButton';

import actions from '@/actions';
import API from '@/helpers/API';

const cx = classNames.bind(styles);

const VideoStream = ({ robot }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(robot.metadata['VIDEO_TYPE'] ?? 'None');
  const [formInfo, setFormInfo] = useState({
    url: '',
    username: '',
    password: '',
    token: '',
  });
  const urlRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const tokenRef = useRef();

  useMountEffect(() => {
    switch (type) {
      case 'RTSP':
        setFormInfo({
          ...formInfo,
          url: robot.metadata['VIDEO_RTSP_URL'],
          username: robot.metadata['VIDEO_RTSP_USERNAME'],
          password: robot.metadata['VIDEO_RTSP_PASSWORD'],
        });
        break;

      case 'WebRTC':
        setFormInfo({
          ...formInfo,
          url: robot.metadata['VIDEO_WEBRTC_URL'],
          token: robot.metadata['VIDEO_WEBRTC_TOKEN'],
        });
        break;

      default:
        break;
    }
  });

  const selectType = (e) => {
    const nextType = e.target.closest('[data-type]').dataset.type;
    setType(nextType);
  };

  const handleFormChange = (e) => {
    setFormInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.code === 'Enter') {
      switch (e.target.name) {
        case 'url':
          switch (type) {
            case 'RTSP':
              usernameRef.current.focus();
              break;

            case 'WebRTC':
              tokenRef.current.focus();
              break;

            default:
              break;
          }
          break;

        case 'username':
          passwordRef.current.focus();
          break;

        case 'password':
        case 'token':
          doSave();
          break;

        default:
          break;
      }
    }
  };

  const doSave = () => {
    const data = { type };
    switch (type) {
      case 'RTSP':
        data.url = formInfo.url;
        data.username = formInfo.username;
        data.password = formInfo.password;
        break;

      case 'WebRTC':
        data.url = formInfo.url;
        data.token = formInfo.token;
        break;

      default:
        break;
    }

    dispatch(actions.robot.updateVideo(robot.id, data));
    return API.patch(`/robots/${robot.id}/video`, data).then((result) => result.success);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('methods')}>
        <div className={cx('method')} data-type="None" onClick={selectType}>
          {type === 'None' && <RiCheckboxCircleFill size={24} color="#41a3ff" />}
          {type !== 'None' && <RiCheckboxBlankCircleLine size={24} color="white" />}
          <div className={cx('title')}>None</div>
          <div className={cx('desc')}>This robot is not equipped with a camera</div>
        </div>
        <div className={cx('method')} data-type="Kurento" onClick={selectType}>
          {type === 'Kurento' && <RiCheckboxCircleFill size={24} color="#41a3ff" />}
          {type !== 'Kurento' && <RiCheckboxBlankCircleLine size={24} color="white" />}
          <div className={cx('title')}>M1Cast</div>
          <div className={cx('desc')}>This robot is equipped with the M1Edge Broadcaster</div>
        </div>
        <div className={cx('method')} data-type="RTSP" onClick={selectType}>
          {type === 'RTSP' && <RiCheckboxCircleFill size={24} color="#41a3ff" />}
          {type !== 'RTSP' && <RiCheckboxBlankCircleLine size={24} color="white" />}
          <div className={cx('title')}>RTSP</div>
          <div className={cx('desc')}>For robots that support RTSP-based streaming</div>
        </div>
        <div className={cx('method')} data-type="WebRTC" onClick={selectType}>
          {type === 'WebRTC' && <RiCheckboxCircleFill size={24} color="#41a3ff" />}
          {type !== 'WebRTC' && <RiCheckboxBlankCircleLine size={24} color="white" />}
          <div className={cx('title')}>SmatiiView</div>
          <div className={cx('desc')}>Available only for robots using the SmatiiView service</div>
        </div>
      </div>
      {['RTSP', 'WebRTC'].includes(type) && (
        <div className={cx('form')}>
          <label>
            URL
            <input
              ref={urlRef}
              name="url"
              type="text"
              defaultValue={formInfo.url}
              onChange={handleFormChange}
              onKeyDown={pressEnter}
            />
          </label>
          {type === 'RTSP' && (
            <>
              <label>
                Username
                <input
                  ref={usernameRef}
                  name="username"
                  type="text"
                  defaultValue={formInfo.username}
                  onChange={handleFormChange}
                  onKeyDown={pressEnter}
                />
              </label>
              <label>
                Password
                <input
                  ref={passwordRef}
                  name="password"
                  type="text"
                  defaultValue={formInfo.password}
                  onChange={handleFormChange}
                  onKeyDown={pressEnter}
                />
              </label>
            </>
          )}
          {type === 'WebRTC' && (
            <label>
              Token
              <input
                ref={tokenRef}
                name="token"
                type="text"
                defaultValue={formInfo.token}
                onChange={handleFormChange}
                onKeyDown={pressEnter}
              />
            </label>
          )}
        </div>
      )}
      <ProcessingButton text="Save" doProcess={doSave} className={cx('button')} />
    </div>
  );
};

export default VideoStream;
