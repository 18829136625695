import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';

import styles from './Vibration.module.scss';

import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Vibration = ({ data: robotId }) => {
  const [vibration, setVibration] = useState({
    vibrationX: 0,
    vibrationY: 0,
    vibrationZ: 0,
    clipping0: 0,
    clipping1: 0,
    clipping2: 0,
  });

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/vibration`, (value) => {
      setVibration({
        vibrationX: value.vibrationX,
        vibrationY: value.vibrationY,
        vibrationZ: value.vibrationZ,
        clipping0: value.clipping0,
        clipping1: value.clipping1,
        clipping2: value.clipping2,
      });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('gauges')}>
        <div className={cx('item')}>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ height: `${vibration.vibrationX}%` }} />
            <div className={cx(['caution', 'v30'])} />
            <div className={cx(['caution', 'v60'])} />
            <div className={cx('value')}>{vibration.vibrationX.toFixed(2)}</div>
          </div>
          <div className={cx('label')}>X</div>
        </div>
        <div className={cx('item')}>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ height: `${vibration.vibrationY}%` }} />
            <div className={cx(['caution', 'v30'])} />
            <div className={cx(['caution', 'v60'])} />
            <div className={cx('value')}>{vibration.vibrationY.toFixed(2)}</div>
          </div>
          <div className={cx('label')}>Y</div>
        </div>
        <div className={cx('item')}>
          <div className={cx('gauge')}>
            <div className={cx('bar')} style={{ height: `${vibration.vibrationZ}%` }} />
            <div className={cx(['caution', 'v30'])} />
            <div className={cx(['caution', 'v60'])} />
            <div className={cx('value')}>{vibration.vibrationZ.toFixed(2)}</div>
          </div>
          <div className={cx('label')}>Z</div>
        </div>
      </div>
      <div className={cx('table')}>
        <div className={cx('header')}>
          <div className={cx('title')}>Clipping</div>
        </div>
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td>Primary</td>
              <td>{vibration.clipping0}</td>
            </tr>
            <tr>
              <td>Secondary</td>
              <td>{vibration.clipping1}</td>
            </tr>
            <tr>
              <td>Tertiary</td>
              <td>{vibration.clipping2}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Vibration;
