import produce from 'immer';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  unread: [],
  robots: {},
};

export default handleActions(
  {
    'NOTIFICATION/RESET': (state, action) => initialState,
    'NOTIFICATION/ADD': (state, action) =>
      produce(state, (draft) => {
        const { robotId, text } = action.payload;

        const notification = {
          id: uuidv4(),
          text,
          time: moment().format('HH:mm:ss'),
        };

        // 읽지 않은 알림
        draft.unread.push({ robotId, ...notification });

        // 로봇별 전체 알림
        if (Object.hasOwn(state.robots, robotId)) {
          draft.robots[robotId].unshift(notification);
        } else {
          draft.robots[robotId] = [notification];
        }
      }),
    'NOTIFICATION/READ': (state, action) =>
      produce(state, (draft) => {
        const { id } = action.payload;

        draft.unread = state.unread.filter((notification) => notification.id !== id);
      }),
    'NOTIFICATION/READ_ALL': (state, action) =>
      produce(state, (draft) => {
        draft.unread = [];
      }),
    'NOTIFICATION/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        draft.unread = state.unread.filter((notification) => notification.robotId !== robotId);
        delete draft.robots[robotId];
      }),
  },
  initialState
);
