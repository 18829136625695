import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    direction: useRef(),
    offset: useRef(),
    heading: useRef(),
    rate: useRef(),
  }).current;

  useEffect(() => {
    domRefs.direction.current.value = missionItem.data.direction;
    domRefs.offset.current.value = missionItem.data.offset;
    domRefs.heading.current.value = missionItem.data.heading;
    domRefs.rate.current.value = missionItem.data.rate;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Direction">
        <select ref={domRefs.direction} name="direction" onChange={handleBlur}>
          <option value="1">Clockwise</option>
          <option value="0">Shortest</option>
          <option value="-1">Counter-Clockwise</option>
        </select>
      </MissionItemRow>
      <MissionItemRow label="Offset">
        <select ref={domRefs.offset} name="offset" onChange={handleBlur}>
          <option value="1">Relative</option>
          <option value="0">Absolute</option>
        </select>
      </MissionItemRow>
      <MissionItemRow label="Heading" unit="deg">
        <input
          ref={domRefs.heading}
          name="heading"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Rate" unit="deg/s">
        <input
          ref={domRefs.rate}
          name="rate"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
