import classNames from 'classnames/bind';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    relay: useRef(),
    count: useRef(),
    time: useRef(),
  }).current;

  useEffect(() => {
    domRefs.relay.current.value = missionItem.data.relay;
    domRefs.count.current.value = missionItem.data.count;
    domRefs.time.current.value = missionItem.data.time;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    let value = Number(e.target.value);

    // Cycle Count 변경된 경우
    if (name === 'count') {
      value = Math.max(1, value);
    }
    // Relay Instance Number, Cycle Time 변경된 경우
    else {
      value = Math.max(0, value);
    }

    dispatch(actions.editor.updateMissionItem(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Relay Instance Number">
        <input
          ref={domRefs.relay}
          name="relay"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Cycle Count">
        <input
          ref={domRefs.count}
          name="count"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Cycle Time" unit="secs">
        <input
          ref={domRefs.time}
          name="time"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
