import classNames from 'classnames/bind';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const missionItems = useSelector((state) => state.editor.missionItems);
  const domRefs = useRef({
    latitude: useRef(),
    longitude: useRef(),
    elevation: useRef(),
    relAltitude: useRef(),
    aglAltitude: useRef(),
    mslAltitude: useRef(),
    turns: useRef(),
  }).current;

  // 홈 지면 고도
  const homeElevation = useMemo(() => {
    if (missionItems.length > 0) {
      return missionItems[0].data.elevation;
    }
    return 0;
  }, [missionItems]);

  const { mslAltitude, aglAltitude } = useMemo(() => {
    const mslAltitude = missionItem.data.altitude + homeElevation;
    const aglAltitude = mslAltitude - missionItem.data.elevation;

    return { mslAltitude, aglAltitude };
  }, [homeElevation, missionItem.data]);

  useEffect(() => {
    domRefs.latitude.current.value = missionItem.data.position.lat.toFixed(7);
    domRefs.longitude.current.value = missionItem.data.position.lng.toFixed(7);
    domRefs.elevation.current.value = missionItem.data.elevation;
    domRefs.relAltitude.current.value = missionItem.data.altitude.toFixed(1);
    domRefs.aglAltitude.current.value = aglAltitude.toFixed(1);
    domRefs.mslAltitude.current.value = mslAltitude.toFixed(1);
    domRefs.turns.current.value = missionItem.data.turns;
  }, [missionItem.data]);

  const updatePosition = (position) => {
    getElevation(position).then((elevation) => {
      const waypoint = { position, elevation };
      dispatch(actions.editor.moveWaypoint(missionItem.id, waypoint));
    });
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);

    // Latitude 변경된 경우
    if (name === 'latitude') {
      updatePosition({ lat: value, lng: missionItem.data.position.lng });
    }
    // Longitude 변경된 경우
    else if (name === 'longitude') {
      updatePosition({ lat: missionItem.data.position.lat, lng: value });
    }
    // Relative Altitude 변경된 경우
    else if (name === 'relAltitude') {
      dispatch(actions.editor.editWaypoint(index, 'altitude', value));
    }
    // AGL Altitude 변경된 경우
    else if (name === 'aglAltitude') {
      const altitude = Number((missionItem.data.elevation + value - homeElevation).toFixed(1));
      dispatch(actions.editor.editWaypoint(index, 'altitude', altitude));
    }
    // MSL Altitude 변경된 경우
    else if (name === 'mslAltitude') {
      const altitude = Number((value - homeElevation).toFixed(1));
      dispatch(actions.editor.editWaypoint(index, 'altitude', altitude));
    }
    // Turns 변경된 경우
    else if (name === 'turns') {
      const turns = Math.max(1, parseInt(value));
      dispatch(actions.editor.editWaypoint(index, 'turns', turns));
    }
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Latitude">
        <input
          ref={domRefs.latitude}
          name="latitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Longitude">
        <input
          ref={domRefs.longitude}
          name="longitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Elevation" unit="m">
        <input ref={domRefs.elevation} name="elevation" type="number" readOnly />
      </MissionItemRow>
      <MissionItemRow label="Relative Altitude" unit="m">
        <input
          ref={domRefs.relAltitude}
          name="relAltitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="AGL Altitude" unit="m">
        <input
          ref={domRefs.aglAltitude}
          name="aglAltitude"
          type="number"
          className={cx({ danger: aglAltitude <= 0 })}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="MSL Altitude" unit="m">
        <input
          ref={domRefs.mslAltitude}
          name="mslAltitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Turns">
        <input
          ref={domRefs.turns}
          name="turns"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
