import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './EditorConfig.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';
import { NotifierService as notifier } from '@/libs/Notifier';

const cx = classNames.bind(styles);

const Modal = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.editor.config);
  const [nextConfig, setNextConfig] = useState(config);
  const altitudeRef = useRef();
  const speedRef = useRef();

  const close = () => {
    modal.hide();
  };

  const handleChange = (e) => {
    setNextConfig({
      ...nextConfig,
      [e.target.name]: Number(e.target.value),
    });
  };

  // 유효성 검사
  const validAll = () => {
    if (Number(nextConfig.altitude) === 0) {
      notifier.error('Please enter value for altitude.');
      altitudeRef.current.focus();
      return false;
    }
    if (Number(nextConfig.speed) === 0) {
      notifier.error('Please enter value for speed.');
      speedRef.current.focus();
      return false;
    }
    return true;
  };

  const doSave = () => {
    if (!validAll()) return;

    dispatch(actions.editor.setConfig(nextConfig));
    close();
  };

  return (
    <ModalWrapper>
      <div className={cx('fields')}>
        <label>
          Default Altitude
          <div className={cx('fieldWrapper')}>
            <input ref={altitudeRef} name="altitude" type="text" value={nextConfig.altitude} onChange={handleChange} />
            <div className={cx('unit')}>m</div>
          </div>
        </label>
        <label>
          Default Speed
          <div className={cx('fieldWrapper')}>
            <input ref={speedRef} name="speed" type="text" value={nextConfig.speed} onChange={handleChange} />
            <div className={cx('unit')}>m/s</div>
          </div>
        </label>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doSave}>
          Save
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
